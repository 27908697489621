<template>
    <PageTitle title="IDtag Orders" :breadcrumb="[ { name: 'IDtag Orders' } ]">
        <span v-if="store.user" style="margin:10px">
            <router-link :to="{  name: 'IDTAGParticularOrder' }"  class="btn btn-success" v-if="store.user.role == 'ASSOC'">
                <i class="ti ti-plus"></i> <span>Change Particular / Photo</span>
            </router-link>
        </span>

        <span v-if="store.user">
            <router-link :to="{  name: 'IDTAGLostOrder' }"  class="btn btn-red" v-if="store.user.role == 'ASSOC'">
                <i class="ti ti-plus"></i> <span>Report Lost IDtag / Wear & Tear</span>
            </router-link>
        </span>
    </PageTitle>
    
    <div class="page-body">
        <div class="container-xl">
            <div class="row row-cards">
                <div class="col-12">
                    <div class="card mb-3">
                        <div class="table-responsive">
                            <table class="table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th class="w-5">Order No</th>
                                        <th>Associate Code</th>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Order At</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                    <tbody>
                                    <tr v-for="(row, index) in orders.data" :key="index">
                                        <td class="text-center">
                                            <router-link :to="{  name: 'IDTAGOrderDetails', params: { id: row.id } }">
                                                {{ row.id }}
                                            </router-link>
                                        </td>
                                        <td>{{ row.order_by }}</td>
                                        <td>{{ row.name }}</td>
                                        <td>{{ row.category_name }}</td>
                                        <td>{{ row.amount_text }}</td>    
                                        <td><OrderStatus :orderStatus="row.status"></OrderStatus></td>
                                        <td>
                                            {{ timeFormat(row.created_at) }}
                                        </td>
                                        <td>
                                            <router-link :to="{ name: 'IDTAGPaymentPage', params: { id: row.id } }" class="btn btn-outline-red btn-sm" v-if="row.status == 'PENDING'">
                                                <i class="ti ti-credit-card"></i> <span>Pay</span>
                                            </router-link> 
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <a class="btn btn-sm btn-ghost-red dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ti ti-dots"></i>
                                                </a>

                                                <ul class="dropdown-menu">
                                                   
                                                    <li v-if="row.status == 'PENDING'"><h6 class="dropdown-header">Actions</h6></li>
                                                    
                                                    <li v-if="row.status == 'PENDING'">
                                                        <router-link :to="{ name: 'IDTAGPaymentPage', params: { id: row.id } }" class="dropdown-item">
                                                            Pay
                                                        </router-link>
                                                    </li>

                                                    <li v-if="row.status == 'PENDING'"><hr class="dropdown-divider"></li>
                                                    
                                                    <li><h6 class="dropdown-header">Connections</h6></li>
                                                    <li>
                                                        <router-link class="dropdown-item" :to="{ name: 'IDTAGOrderDetails', params: { id: row.id } }">
                                                            View Details <i class="ti ti-arrow-right"></i>
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer paginationBox">
                            <v-pagination
                                v-model="meta.current_page"
                                :pages="meta.last_page"
                                :range-size="1"
                                active-color="#DCEDFF"
                                @update:modelValue="goSearch"
                            />
                            <div class="item_counter">1 of {{ meta.last_page }} ({{ meta.total }} items)</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import PageTitle from '../../components/PageTitle.vue';
import OrderStatus from './components/OrderStatus.vue';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import VPagination from "@hennge/vue3-pagination";
import axios from 'axios';
import { store } from '../../store.js';
import { timeFormat } from '@/helpers/me';

export default {
    components: {
        PageTitle,
        OrderStatus,
        VPagination
    },
    setup(){
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const orders = ref([]);
        const meta = ref([]);
        const select = ref(null);
        const options =  ['list', 'of', 'options'];

        async function getInfo() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/idtag/orders',{ 
                params: route.query
            })
            .then(function (res) {
                store.loading = false;
                orders.value = res.data;
                meta.value = res.data.meta;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }else if (error.response && error.response.status == 403) {
                    // router.push({ name: 'IDTAGManage' });
                    router.push({ name: 'Home' });
                }else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        onMounted(() => {
            if (!localStorage.getItem('authToken')) {
                router.push({ name: 'Logout' });
                return false;
            }
            getInfo();
        });

        return { router, swal, orders, store, meta, select, options, timeFormat };
    },
    methods: {
        async goSearch() { 
            store.loading = true;
            let query = {
                page: this.meta.current_page
            }
            let model = this;
            this.router.push({ name: 'IDTAG', query: query });
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/idtag/orders',{ 
                params: query
            })
            .then(function (res) {
                store.loading = false;
                model.orders = res.data;
                model.meta = res.data.meta;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    }
}

</script>

<style scoped>
.dropdown-toggle:after {
    display: none;
}
.dropdown-toggle.no-arrow-down:after {
    display: none;
}
</style>