<template>
    <PageTitle title="Order Summary" :breadcrumb="[{ name: 'Name Card', link: '/namecard' }, { name: 'Order Summary' } ]">
    </PageTitle>

    <div class="page-body">
        <div class="container-xl">
            <div class="row row-cards">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h2>Order Details</h2>
                            <div class="row">
                                <div class="col-6 col-sm-2">Item Name </div>
                                <div class="col-6 col-sm-2">: ERA Name Card</div>

                                <div class="w-100"></div>

                                <div class="col-6 col-sm-2">Total Payment</div>
                                <div class="col-6 col-sm-2">: SGD 20 or 100 Points</div>

                                <div class="w-100"></div>

                                <div class="col-6 col-sm-2">Quantity</div>
                                <div class="col-6 col-sm-2">: 1 /box</div>

                                <div class="mt-3"></div>

                                <div class="w-100"></div>

                                <div class="col-6 col-sm-2">Amount To Pay</div>
                                <div class="col-6 col-sm-2">: SGD {{150 - rangeSelectValue}} </div>

                                <div class="w-100"></div>

                                <div class="col-6 col-sm-2">ERA Points To Pay</div>
                                <div class="col-6 col-sm-2">: {{rangeSelectValue}} ERA Points</div>

                                <div class="w-100 mt-5"></div>
                            </div>

                            <div class="mt-3">
                                <div class="row no-gutters mb-3">
                                </div>
                                <div>
                                    <button class="btn btn-outline-primary" @click="payPoint"><i class="ti ti-coin"></i> <span>Pay by ERA Points</span></button>
                                </div>
                                <div>
                                    <h3>Payment Options</h3>
                                    <div class="mt-3 row">
                                        <div class="col-md-2 col-sm-12 col-xs-12">
                                            <div class="form-selectgroup form-selectgroup-boxes">
                                                <label class="form-selectgroup-item flex-fill">
                                                    <input type="radio" name="form-payment" value="paypal" class="form-selectgroup-input" @click="paynowSectionToggle">
                                                    <div class="form-selectgroup-label d-flex align-items-center p-3">
                                                    <div class="me-3">
                                                        <span class="form-selectgroup-check"></span>
                                                    </div>
                                                    <div>
                                                        <span class="payment payment-provider-paypal payment-xs me-2"> <img src="https://d2u1upwdbw709w.cloudfront.net/documents/EXTERNAL+DOCUMENTS/MYERA/paynow-btn.png" width="60"></span>
                                                    </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="paynowQRCodeShow">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import PageTitle from '../../components/PageTitle.vue';
import {inject, reactive, computed} from 'vue';
import axios from 'axios';

export default {
    components: {
        PageTitle
    },
    setup(){
        const swal = inject('$swal');
        const rangeSliderElement = reactive({
            rangeValue: 100,
            rangeDynamicRange : 0,
            paynowQRCodeSection: false
        });
        let rangeSelectValue = computed({
            get() {
                return rangeSliderElement.rangeValue
            },
            set(value){
                rangeSliderElement.rangeDynamicRange = value
            }
        })
        let paynowQRCodeShow = computed({
            get() {
                return rangeSliderElement.paynowQRCodeSection
            },
            set(value){
                rangeSliderElement.paynowQRCodeSection = value
            }
        });
        const selectRange = (rangeValue) => {
            if(rangeValue == 100)
            {
                rangeSelectValue = false;
            } else {
                rangeSelectValue = true;
            }
        }

        function payPoint(){
            swal('Success','You have successfully made payment!','success');
        }

        function paynowSectionToggle() {
            paynowQRCodeShow.value = !paynowQRCodeShow.value;
            axios.get('https://mytest.era.com.sg/QRCodeGenerator?source=ERA&amount=' + 150 + '&billReferenceNumber=' + "NameCardTest" +'&expiry_time=6')
            .then(function (res) {
                console.log(res);
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        return{
            value: 100, rangeSliderElement, selectRange, rangeSelectValue, paynowSectionToggle, paynowQRCodeShow, payPoint
        }
    },
}
</script>