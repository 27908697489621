<template>
  <div class="row">
    
	</div><!--row -->
</template>

<script>
import { onMounted } from 'vue';
import { useRouter } from "vue-router";
import axios from 'axios';
import { store } from '../../store.js';

export default {
    setup() {
        const router = useRouter();

        async function logout() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/auth/logout')
            .then(function () {
                store.loading = false;
                localStorage.removeItem('authToken');
                window.location.href = '/login';
                return false;
            })
            .catch(function () {
                store.loading = false;
                localStorage.removeItem('authToken');
                window.location.href = '/login';
            });
        }

        onMounted(() => {
            if (router.options.history.state.back) {
                localStorage.setItem('referrer',router.options.history.state.back);
            }
            logout();
        });

        return {  };
    }
}
</script>