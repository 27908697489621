<template>
    <div class="row mb-2" v-if="store.startCountdown">
        <div class="col-md-12">
            <div class="alert alert-warning" role="alert">
                <!-- 300000 5 minutes -->
                <!-- 60000 1 minutes -->
                <h4 class="alert-title">
                    <vue-countdown ref="countdown" :time="300000" v-slot="{ minutes, seconds }" @end="onCountdownEnd" @progress="handleCountdownProgress">
                        The QR code will expire in <span class="text-danger fw-bold fs-2"> {{ minutes }}:{{ seconds }} </span> 
                    </vue-countdown>
                </h4>
                <div class="text-muted">Please scan to pay</div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col-md-12 d-flex justify-content-center">
            <div class="qrcode pending" v-if="status == 'PENDING'">
                <img :src="store.qrcodeSource">
            </div>
            <div class="qrcode expired" v-if="status == 'EXPIRED'">
                <button class="btn btn-primary" @click="refreshQRCode"><i class="ti ti-refresh"></i> <span>Refresh QR Code</span></button>
            </div>
            <div class="qrcode success" v-if="status == 'SUCCESS'">
                <i class="ti ti-circle-check"></i>
            </div>
        </div>
    </div>

    <div class="row mb-2 mt-4">
        <label class="form-label">Please follow these instructions</label>
        <ol class="ins text-muted">
            <li>Please scan the QR code and make payments using PayNow supporting apps.</li>
            <li>Make sure the recipient is <strong>ERA REALTY NETWORK PTE LTD</strong></li>
            <li>If you have successfully made the payment but the page is not automatically redirected, please refresh after 30 mins. If the payment status is still not updated. please contact ERA IT Support.</li>
        </ol>
    </div>
</template>
<script>
import { store } from '../store.js';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import moment from 'moment';
import axios from 'axios';

export default {
    components: {
        
    },
    setup() {
        const status = ref('PENDING');
        const swal = inject('$swal');
        const app = getCurrentInstance();
        const router = useRouter();
        store.startCountdown = false;

        async function capturePendingPayment() {
            store.loading = true;
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/namecard/pay/paynow',{ 
                refNo: store.pay.id,
                service: store.pay.service
            })
            .then(function (res) {
                store.loading = false;
                generateQRCode(res.data.paymentRefId);
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        async function generateQRCode(paymentRefId) {
            store.loading = true;
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/namecard/pay/paynow/qrcode',{ 
                ref_no: paymentRefId,
                expiry_time: 6
            })
            .then(function (res) {
                store.loading = false;
                store.qrcodeSource = res.data.qrcode_src;
                store.startCountdown = true;
                store.paymentRefId = paymentRefId;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        onMounted(() => {
            capturePendingPayment();
        });

        return {
            store, app, router, status, swal
        }
    },
    methods: {
        onCountdownEnd() {
            this.status = 'EXPIRED';
            this.bufferTime();
        },
        refreshQRCode() {
            this.status = 'PENDING';
            this.router.go();
        },
        timeFormat: function (value) {
            if (value) {
                return moment(value).format("D MMM YYYY hh:mm A");
            }
        },
        bufferTime() {
            let time = 60;
            let model = this;
            let timer = setInterval(function() {
                if (time === 0) { //check if time is over 
                    clearInterval(timer); //clear the interval
                } else {
                    time--;
                    model.paymentCheck();
                    if(model.status == 'SUCCESS')
                    {
                        clearInterval(timer); //clear the interval 
                    }
                }
            }, 1000);
        },
        handleCountdownProgress() {
            this.paymentCheck();
        },
        paymentCheck() {
            let model = this;

            // Check paynow pending payments
            axios
            .get(process.env.VUE_APP_ENDPOINT+'/namecard/pay/paynow/payment/check',{ 
                params: {paymentRefNo: store.paymentRefId},
            })
            .then(function (res) {
                if(res.data.confirmStatus)
                {
                    model.startCountdown = false;
                    model.status = 'SUCCESS';
                    model.$refs.countdown.end();
                    model.swal({
                        icon: 'success',
                        html: res.data.message,
                        showCloseButton: true,
                        showConfirmButton: true
                    }).then(function(){
                        model.router.push({ name: store.pay.redirect });
                    });
                }
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    }
}
</script>

<style scoped>
    .ins {
        margin-left: 15px;
    }
    .qrcode {
        height: 300px;
        width: 300px;   
        border: red;
    }
    
    .qrcode.expired {
        background: url('https://d2u1upwdbw709w.cloudfront.net/documents/EXTERNAL+DOCUMENTS/MYERA/paynow_qrcode_expire.png');
        background-size: 300px 300px;
        position: relative;
    }

    .qrcode.expired .btn {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 24%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .qrcode.success {
        background: url('https://d2u1upwdbw709w.cloudfront.net/documents/EXTERNAL+DOCUMENTS/MYERA/paynow_qrcode_expire.png');
        background-size: 300px 300px;
        position: relative;
    }

    .qrcode.success i {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 38%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 80px;
        color: #2fb344;
    }

</style>