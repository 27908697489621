<template>
    <div>&nbsp;</div>
</template>
    
    <script>
    import { ref, onMounted, inject, getCurrentInstance } from 'vue';
    import { useRouter, useRoute } from "vue-router";
    import axios from 'axios';
    import { store } from '../../store.js';
    
    export default {
        components: {
        },
        setup() {
            const app = getCurrentInstance();
            const swal = inject('$swal');
            const router = useRouter();
            const route = useRoute();
            const user = ref([]);
            const error = ref([]);

            async function checkToken() {
                store.loading = true;
                
                axios.interceptors.request.use(function (config) {
                    config.headers.Authorization =  'Bearer ' + localStorage.getItem('authToken');
                    return config;
                }, function (error) {
                    return Promise.reject(error);
                });

                await axios
                .get(process.env.VUE_APP_ENDPOINT+'/auth/info')
                .then(function () {
                    store.loading = false;
                    window.location.href = '/'+route.query.page;
                })
                .catch(function (error) {
                    store.loading = false;
                    if (error.response && error.response.status == 401) {
                        localStorage.removeItem('authToken');
                        window.location.href = '/login';
                    } else {
                        swal({
                            icon: 'error',
                            text: app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            }
    
            onMounted(() => {
                if (!route.query.page || !route.query.token) {
                    window.location.href = process.env.VUE_APP_MAINPAGE;
                    return false;
                }
                localStorage.setItem('authToken',route.query.token);
                checkToken();
            });
    
            return { router,route, user, error, store };
        }
    }
    </script>