<template>
    <PageTitle title="IDtag Payment" :breadcrumb="[ { name: 'IDtag', link: '/idtag' }, { name: 'Payment' } ]"></PageTitle>

    <div class="page-body">
        <div class="container-xl">
            <div class="row row-cards">
                <div class="col-12">
                    <PaymentForm v-if="store.showPaymentForm"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentForm from '../../components/IDTagPaymentForm.vue';
import PageTitle from '../../components/PageTitle.vue';
import { store } from '../../store.js';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';

export default {
    components: {
        PageTitle,
        PaymentForm
    },
    setup() {
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const user = ref([]);
        store.showPaymentForm = false;

        async function getInfo() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/idtag/user')
            .then(function (res) {
                // if(res.data.user.role == 'STAFF') {
                //     router.push({ name: 'IDTAGManage' });
                // }
                store.loading = false;
                user.value = res.data.user;
                store.idtag_pay.total_points = res.data.user.total_points;
                store.idtag_pay.total_points_text = res.data.user.total_points_text;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        async function getOrder() { 
            //store.loading = true;
            await axios  
            .get(process.env.VUE_APP_ENDPOINT+'/idtag/orders/'+route.params.id+'/checkout')
            .then(function (res) {
                store.loading = false;
                //store.pay.id = res.data.data.id;
                store.idtag_pay.name        = res.data.data.category_name;
                store.idtag_pay.description = res.data.data.sub_category_str;

                store.idtag_pay.amount = res.data.data.price;
                store.idtag_pay.amount_text = res.data.data.amount_text;
                store.idtag_pay.points = res.data.data.era_points;
                store.idtag_pay.due    = res.data.data.payment_expiry_date;
                store.idtag_pay.ref_id = res.data.data.id;
                store.idtag_pay.status = res.data.data.payment_status;

                store.idtag_pay.service ='IDTAG';
                store.idtag_pay.redirect= 'IDTAG';
                store.showPaymentForm = true;

                if (res.data.data.payment_status == 'COFM') {
                    router.push({ name: 'IDTAGOrderDetails', params: { id:route.params.id } });
                }
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }else if (error.response && error.response.status == 403) {
                    // router.push({ name: 'IDTAGManage' });
                    router.push({ name: 'Home' });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        onMounted(() => {
            getInfo();
            getOrder();
        });

        return { store, swal, router, user };
    }
}
</script>