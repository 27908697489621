<template>
    <div class="card mb-3">
        <div class="card-body mb-4">
            <h2 class="mb-3 text-muted">Order Summary</h2>
            <div class="table-responsive">
                <table class="table table-vcenter">
                    <thead>
                        <tr>
                            <th>Item Name</th>
                            <th>Quantity</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ store.pay.name }}</td>
                            <td>{{ store.pay.description}}</td>
                            <td>{{ store.pay.amount_text }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mb-3 mt-5">
                <div class="mb-3 col-md-12">
                    <label class="form-label">Payment method</label>
                    <div class="form-selectgroup form-selectgroup-boxes">
                        <label class="form-selectgroup-item flex-fill">
                            <input type="radio" v-model="method" value="PAYNOW" class="form-selectgroup-input">
                            <div class="form-selectgroup-label d-flex align-items-center p-3">
                            <div class="me-3">
                                <span class="form-selectgroup-check"></span>
                            </div>
                            <div>
                                <div class="pay paynow"></div>
                            </div>
                            </div>
                        </label>
                        <label class="form-selectgroup-item flex-fill" v-if="store.pay.points >= (store.pay.amount * 20 )">
                            <input type="radio" v-model="method" value="POINTS" class="form-selectgroup-input">
                            <div class="form-selectgroup-label d-flex align-items-center p-3">
                                <div class="me-3">
                                    <span class="form-selectgroup-check"></span>
                                </div>
                                <div>
                                    <div class="font-weight-medium">Use ERA points</div>
                                    <div class="text-muted">Balance: You have {{ store.pay.points_text }} ERA points</div>
                                </div>
                            </div>
                        </label>
                        <label class="form-selectgroup-item flex-fill" v-else>
                            <input type="radio" v-model="method" value="POINTS" class="form-selectgroup-input" disabled="disabled">
                            <div class="form-selectgroup-label d-flex align-items-center p-3">
                                <div class="me-3">
                                    <span class="form-selectgroup-check"></span>
                                </div>
                                <div>
                                    <div class="font-weight-medium">Not enough ERA points</div>
                                    <div class="text-muted">Item requires {{ (store.pay.amount * 20 ) }} ERA points</div>
                                    <div class="text-muted">You have balance {{ store.pay.points_text }} ERA points</div>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-12">
                    <PaymentPaynow v-if="store.pay.method == 'PAYNOW' && store.pay.status != 'PAID'"/>
                </div>
            </div>

            <button type="submit" class="btn btn-success btn-lg ms-auto" @click="paypoints" v-if="store.pay.method == 'POINTS'">
                <span>Confirm & Pay</span> 
            </button>
        </div>

        <div class="card-footer text-end">
            <div class="d-flex">
                <router-link :to="{ name: 'Namecard' }" class="btn btn-link">Cancel</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentPaynow from '../components/PaymentPaynow.vue';
import { store } from '../store.js';
import { ref, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';

export default {
    components: {
        PaymentPaynow
    },
    setup() {
        const method = ref(store.pay.method);
        const app = getCurrentInstance();
        const router = useRouter();
        const swal = inject('$swal');
        const route = useRoute();

        return  { app, router, route, swal, store, method };
    },
    watch: {
        async method() {
            store.loading = true;
            let model = this;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/namecard/orders/'+this.route.params.id)
            .then(function (res) {
                store.loading = false;
                store.pay.id = res.data.order.id;
                store.pay.description = res.data.order.package_name;
                store.pay.amount = res.data.order.package_amount;
                store.pay.amount_text = res.data.order.package_amount_text;
                store.pay.due = res.data.order.order_expiration;
                store.pay.payment_refno = res.data.order.payment_refno;
                store.pay.status = res.data.order.status;
                store.pay.service = 'namecard';
                store.pay.redirect = 'Namecard';
                store.showPaymentForm = true;

                if (res.data.order.status == 'PAID') {
                    model.router.push({ name: 'OrderDetails', params: { id: model.route.params.id } });
                } else {
                    store.pay.method = model.method;
                }
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    },
    methods: {
        async paypoints() {
            let model = this;
            store.loading = true;
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/namecard/pay/points',{ 
                refNo: store.pay.id,
                service: store.pay.service
            })
            .then(function (res) {
                store.loading = false;
                model.swal({
                    icon: 'success',
                    html: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                });
                model.router.push({ name: store.pay.redirect });
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    }
}
</script>
<style scoped>
    .pay.paynow {
        width: 50px; height: 40px; display:block;
        background-image: url('https://d2u1upwdbw709w.cloudfront.net/documents/EXTERNAL+DOCUMENTS/NAMECARD/ICONS/paynow.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position:  center, 50%, 50%;
    }
    .ins {
        margin-left: 15px;
    }

    .form-selectgroup-label:hover {
        border-color: var(--tblr-primary);
    }
</style>