
function removeSpecialCharNricName(nricName) {
    if(nricName){
        let nric_name=nricName.toUpperCase();
        const myArray = nric_name.split("(");
        return myArray[0]; 
    }
}


function removeInitialBusinessName(businessName){
    if(businessName){
        let business_name=businessName.toUpperCase();
        var removeSingleWord='';
        const words = business_name.split(' ');

        for (let i = 0; i < words.length; i++) {
            const word = words[i];

            if (word.length !== 1) {
                removeSingleWord+=' ';
                removeSingleWord+=words[i];
            }
        }
        let businessNameNoInitial=removeSingleWord.trim();
        return '('+businessNameNoInitial+')';
    }
}

function totalRecord(associateList){
    if(associateList){
        let associateArray=associateList.trim().split('\n');
        return associateArray.length;
    }
    return 0;
   
}
export { removeSpecialCharNricName,removeInitialBusinessName,totalRecord };