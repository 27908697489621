<template>
    <PageTitle title="IDtag Mass Export" :breadcrumb="[ { name: 'IDtag', link: '/idtag/manage' }, { name: 'Mass Export' } ]">
    </PageTitle>

    <div class="page-body">
        <div class="container-xl">
            <div class="row row-cards">
                <!-- <div class="col-lg-12"> -->
                <div class="col-lg-12">
                    <div class="card mb-3">
                        <div class="card-header">
                            <div class="card-title">Mass Export</div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="row">
                                        <div class="col-6 required">
                                            Associate codes
                                        </div>
                                        <br/>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <textarea v-model="assoc_list" class="form-control"  @change="checkValidation('assoc_list')"  rows="20" placeholder="Copy and paste associate codes from excel" :class="{ 'is-invalid': error.assoc_list}"></textarea>
                                            <div class="invalid-feedback">{{ error.assoc_list }}</div>
                                        </div>
                                        <div class="col-2" style="margin-top:390px; color:#008">
                                            <h4>
                                                Total : <span style="border:0px;width:50px">{{totalRecord(assoc_list)}}</span>
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-2">
                                            <button class="btn btn-success"  @click="exportIDTAGImage()">
                                                <span>Export Images</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-8">

                                    <div class="table-responsive">
                                        <table class="table table-vcenter card-table">
                                            <thead>
                                                <tr>
                                                    <th>Created at</th>
                                                    <th>Status</th>                                         
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(row, index) in orders" :key="index">
                                                    <th> {{ timeFormat(row.created_at) }}</th>
                                                    <th>
                                                        <span v-if="row.status=='done' || row.status=='completed' ">Completed</span>
                                                        <span v-else>{{ capitalized(row.status) }}</span>
                                                    </th>
                                                    <th>
                                                        <span v-if="row.status=='done' || row.status=='completed' ">
                                                            <a :href="'/idtag/manage/mass-export?download='+row.mass_download_id" class="btn btn-secondary">Download</a>
                                                        </span>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </div>
    </div>

</template>

<script>
import PageTitle from '../../components/PageTitle.vue';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import { store } from '../../store.js';
import { timeFormat } from '@/helpers/me';
import { totalRecord } from '@/helpers/idtag/filterName';
export default {
    components: {
        PageTitle
    },
    setup(){
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const assoc_list = ref();
        const error = ref([]);
        const total_records=ref(0);

        const orders=ref([]);
        async function downloadMassExportFile(){ 

            let download_id=route.query.download;
            
            if(download_id){
                store.loading = true;
                await axios({
                    url: process.env.VUE_APP_ENDPOINT+'/idtag/downloadMassExport/'+download_id,
                    method:'get',
                    responseType:'blob'
                })
                .then(function (response) {
                    store.loading = false;
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const a = document.createElement('a');
                    a.href=url;
                    a.download =download_id+'.zip';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    router.push({ name: 'IDTAGMassExport'});
                })
                .catch(function (error) {
                
                    store.loading = false;
                    
                    if (error.response && error.response.status == 401) {
                        router.push({ name: 'Logout' });
                    } else if (error.response && error.response.status == 404) {
                        router.push({ name: 'IDTAGManage' });
                    } else if (error.response && error.response.status == 403) {
                        router.push({ name: 'Home' });
                    } else if (error.response && error.response.status == 422) {
                        swal({
                            icon: 'error',
                            text: error.response.data.message,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    } else {
                        swal({
                            icon: 'error',
                            text: app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            }
        }

        async function massExportList() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/idtag/massExport')
            .then(function (res) {
                store.loading = false;
                orders.value = res.data.data;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else if (error.response && error.response.status == 403) {
                    router.push({ name: 'Home' });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        onMounted(() => {
            // if (!localStorage.getItem('authToken')) {
            //     router.push({ name: 'Logout' });
            //     return false;
            // }
            downloadMassExportFile();
            massExportList();
        });
        return {app,store, router, route, swal,assoc_list,error,total_records,totalRecord,orders,timeFormat};
    },
    methods: {

        validate(){
            this.error.form = false;
            this.clear();
            if (!this.assoc_list) {
                this.error.form = true;
                this.error.assoc_list = 'Associate code is required.';
            }
            if(totalRecord(this.assoc_list)>1000){
                this.error.form = true;
                this.error.assoc_list = 'System does not allow over 1000 records for mass export';
            }
        },
        checkValidation(error_class){
            this.error.form = false;
            this.error[error_class]='';
        },
        clear(cl_err) {
            if(!cl_err)
            this.error = [];
            else{
                this.error.form = true;
                this.error={cl_err :''}
            }
        },
        capitalized(name) {
          const capitalizedFirst = name[0].toUpperCase();
          const rest = name.slice(1);

          return capitalizedFirst + rest;
        },
        async exportIDTAGImage() { 
            let model = this;
            this.validate();

            if (!this.error.form) {
                store.loading = true;

                let associateArray=this.assoc_list.trim().split('\n');

                await axios({
                    url: process.env.VUE_APP_ENDPOINT+'/idtag/massExport',
                    method:'POST',
                    data:{
                        associateList:associateArray
                    }
                }).then( response =>{
                    store.loading = false;
                    model.swal({
                        icon: 'success',
                        text: response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                })
                .catch(function (error) {
                    store.loading = false;
                    if (error.response && error.response.status == 401) {
                        model.router.push({ name: 'Logout' });
                    } else if (error.response && error.response.status == 404) {
                        model.router.push({ name: 'IDTAGManage' });
                    } else if (error.response && error.response.status == 403) {
                        model.router.push({ name: 'Home' });
                    } else if (error.response && error.response.status == 422) {
                        model.swal({
                            icon: 'error',
                            text: error.response.data.message,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    } else {
                        model.swal({
                            icon: 'error',
                            text: model.app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            }
        },
    }
}
</script>
<style>
    .required:before {
        content:" *";
        color: #d63939;
        font-size: 16px;
    }
</style>
