import { store } from '../../store.js';

function profilePhoto(photo)
{
    // Default porfile picture
    fetch(photo.src)
    .then(res => res.blob())
    .then(blob => {
        const file = new File([blob], store.namecard.user.cea_regno+'_profile.png', blob);
        store.namecard.user.profile_default_photo = file;
    });
}

function realtyWatchQRCode(qrcode)
{
    const backQRCodeData = new Image(); // convert canvas to png format
    backQRCodeData.src = qrcode.toDataURL();

    // RealtyWatch QRCode
    fetch(backQRCodeData.src)
    .then(res => res.blob())
    .then(blob => {
        const file = new File([blob], store.namecard.user.cea_regno+'_qrcode.png', blob);
        store.namecard.user.realtywatch_qrcode = file;
    });
}
export { profilePhoto, realtyWatchQRCode };