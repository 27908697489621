<template>
    
<PageTitle title="Add IDtag" :breadcrumb="[ { name: 'IDtag', link: '/idtag' }, { name: 'Change of Particular or Photo' } ]"></PageTitle>

<div class="page-body">
    <div class="container-xl">
        <div class="row row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            <h1 class="card-title">Change of Particular / Photo on the IDtag</h1>
                        </div>
                    </div>

                    <div class="card-body mb-4">
                        <div class="row mt-3">
                            <div class="col-lg-5">
                                <div class="mb-4">
                                    <label class="row mb-1">
                                        <span class="col">{{ subCategory[0].name }}</span>  <!--  ERA Coporate Photo -->

                                        <span class="col">
                                            <label class="form-check form-switch">
                                                <input class="form-check-input" :value="subCategory[0].id" v-model="subCategoryIds" type="checkbox" @change="photoToggle" :checked="photoToggleLabel">
                                                <span class="form-check-label"> {{photoToggleLabel ? "  Yes" : "  No"}} </span>
                                            </label>
                                        </span>  
                                        
                                    </label>

                                    <div v-if="photoToggleLabel">

                                        <input type="file" id="coporate_photo_file" name="coporate_photo_file" class="form-control" @change="showPreview()" :class="{ 'is-invalid': error.coporate_photo_file}">
                                        <div class="invalid-feedback">{{ error.coporate_photo_file }}</div>
                                        <lable class="photo_remark">
                                            *Male: White collar shirt, ERA blazer with ERA logo, red tie <br/>
                                            *Female: While collar shirt, ERA blazer with ERA logo <br/>
                                            *Only allow half-body photo
                                        </lable>
                                        
                                    </div>
                                </div>

                                <div class="mb-4">

                                    <label class="row mb-2">
                                        <span class="col">{{ subCategory[1].name }}</span>  <!--  NRIC name -->
            
                                        <span class="col">
                                            <label class="form-check form-switch">
                                                <input class="form-check-input" :value="subCategory[1].id" v-model="subCategoryIds"  type="checkbox" @change="nricNameToggle" :checked="nricNameToggleLabel" >
                                                <span class="form-check-label">{{nricNameToggleLabel ? "  Yes" : "  No"}}</span>
                                            </label>
                                        </span>  
                            
                                    </label>
                                
                                    <div v-if="nricNameToggleLabel">

                                        <input type="text" placeholder="type" class="form-control" :readonly="nricNameToggleLabel?readonly:''" v-model="nric_name"  :class="{ 'is-invalid': error.nric_name}">
                                        
                                        <div class="invalid-feedback">{{ error.nric_name }}</div>

                                        <br/>
                                        
                                        <label class="required">Upload a deed poll</label>
                                
                                        <br/><br/>
                              
                                        <input type="file" class="form-control" name="deep_poll_file" @change="checkValidation('deep_poll_file')" id="deep_poll_file" :class="{ 'is-invalid': error.deep_poll_file}">
                                     
                                        <div class="invalid-feedback">{{ error.deep_poll_file }}</div>

                                        <br/>
                                  
                                        <label class="required">Upload NRIC (front view)<br/></label>
                                       
                                        <br/><br/>

                                        <input type="file" class="form-control" name="nric_file" id="nric_file" @change="checkValidation('nric_file')" :class="{ 'is-invalid': error.nric_file}">
                                        <!-- <lable>*Please upload NRIC (front view) (*compulsory)</lable> -->
                                        <div class="invalid-feedback">{{ error.nric_file }}</div> 
                                    </div>
                                </div>

                                <div class="mb-4">
                                    <label class="row mb-1">
                                        <span class="col">{{ subCategory[2].name }}</span>   <!--  Business Testing -->
                                        <span class="col">
                                            <label class="form-check form-switch">
                                               <input class="form-check-input" :value="subCategory[2].id" v-model="subCategoryIds" type="checkbox" @change="businessNameToggle" :checked="businessNameToggleLabel">
                                               <span class="form-check-label">{{businessNameToggleLabel ? "  Yes" : "  No"}}</span>
                                            </label>
                                        </span>
                                    </label>  
                                   
                                    <div v-if="businessNameToggleLabel">
                                        <input type="text" placeholder="type"  class="form-control" v-model="business_name" :class="{ 'is-invalid': error.business_name}">
                                        <div class="invalid-feedback">{{ error.business_name }}</div>
                                    </div> 
                                </div>

                                <div class="mb-4">
                                    <div class="d-flex">
                                       
                                        <router-link :to="{ name: 'IDTAG' }" class="btn btn-primary"  style="margin-right:10px">
                                            <i class="ti ti-chevron-left"></i>
                                            <span>Go Back</span>
                                        </router-link>
                                        
                                        <button type="submit" class="btn btn-success" @click="submit"  :disabled="(photoToggleLabel || nricNameToggleLabel || businessNameToggleLabel)?disabled:''" >
                                            <span>Proceed to Payment</span> 
                                            <i class="ti ti-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-7">
                                <CustomTa1 :business_name="business_name" :nric_name="nric_name" :cea_regno="cea_regno" :coporate_photo="coporate_photo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import PageTitle from '../../components/PageTitle.vue';
import CustomTa1 from './components/CustomTa1.vue';
import { ref, onMounted, inject , getCurrentInstance } from 'vue';
// import { useRouter, useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { store } from '../../store.js';
import axios from 'axios';

export default {
    components: {
        PageTitle,
        CustomTa1
    },
    setup() {
        const app = getCurrentInstance();
        const router =useRouter();
        // const route = useRoute();

        const swal = inject('$swal');
        const form = ref([]);
    
        const info = ref([]);
        const error = ref([]);
        const ranknamecnToggle = ref(false);
       
        const photo = ref('');

        const photoToggleLabel = ref(false);
        const nricNameToggleLabel = ref(false);
        const businessNameToggleLabel = ref(false);

        const subCategory = ref([{},{},{}]);
        const business_name  = ref('');
        const nric_name      = ref('');
        const coporate_photo = ref('');
        const cea_regno      = ref('');

        const subCategoryIds = ref([]);
        const categoryId     = ref('');

        const files      = ref([]);
        const file_types = ref([]);
        const orderID    = ref('');

        async function getInfo() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/idtag/user')
            .then(function (res) {
                // if(res.data.user.role=='STAFF'){
                //    router.push({ name: 'IDTAGManage' });
                // }
                store.loading = false;
                store.idtag.user = {
                    nric_name: res.data.user.nric_name,
                    coporate_photo: res.data.user.photo,
                    business_name :res.data.user.business_name,
                    cea_regno: res.data.user.cea_regno
                }
                business_name.value = res.data.user.business_name;
                nric_name.value     = res.data.user.nric_name;
                coporate_photo.value= res.data.user.photo;
                cea_regno.value     = res.data.user.cea_regno;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        async function getSubCateogry(category_id=1) {
            categoryId.value=category_id;
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/idtag/subcategory/'+category_id) //1 for change particular or photo
            .then(function (res) {
                subCategory.value=res.data.data;
                subCategoryIds.value=[     //default field 
                    // res.data.data[0].id,
                    // res.data.data[1].id,
                    // res.data.data[2].id,
                ]; 
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }else if (error.response && error.response.status == 403) {
                    // router.push({ name: 'IDTAGManage' });
                    router.push({ name: 'Home' });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
        onMounted(() => {
            getInfo();
            getSubCateogry(1);
        });

        return { store, swal, router, form, info, error,subCategory, ranknamecnToggle, nricNameToggleLabel, photo, photoToggleLabel, businessNameToggleLabel ,business_name, nric_name, coporate_photo,cea_regno,subCategoryIds,categoryId,files,file_types,orderID}
    },
    methods: {
        showPreview() { 
            this.error.form = false;
            this.error.coporate_photo_file = '';
            
            var fileElement = document.getElementById('coporate_photo_file');
            this.info.photo = URL.createObjectURL(fileElement.files[0]);

            var preview = document.getElementById('profile_photo');
            preview.src = this.info.photo;
        },

        checkValidation(error_class){
            this.error.form = false;
            this.error[error_class]='';
        },

        async importMultipleFile(order_id,files,file_types){
            //store.loading = true;
            let model =this;

            var formData = new FormData();
            formData.append("order_id", order_id);

            for (var i = 0; i < file_types.length; i++) {
                formData.append("files[]", files[i]);
                formData.append("file_types[]",file_types[i]);
            }
 
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/idtag/multipleFileUpload',formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function () {
                //can store file name. store.idtag if required.
                //store.loading = false;
                // let orderID   = model.orderID;
                // localStorage.removeItem('idtag');
                // model.router.push('/idtag/payment/'+orderID);
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status != 500) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },

        validate(){
            
            this.error.form = false;
            this.clear();

            if (this.photoToggleLabel) {
                if(!document.getElementById('coporate_photo_file').files.length){
                    this.error.form = true;
                    this.error.coporate_photo_file = 'ERA Corporate Photo is required';
                }
            }
            if (this.nricNameToggleLabel) {
                if(!this.nric_name){
                    this.error.form = true;
                    this.error.nric_name = 'NRIC Name is required';
                }
                if(!document.getElementById('deep_poll_file').files.length){
                    this.error.form = true;
                    this.error.deep_poll_file = 'Deep poll file is required';
                }
                if(!document.getElementById('nric_file').files.length){
                    this.error.form = true;
                    this.error.nric_file = 'NRIC (front view) is required';
                }
            }
    
            if (this.businessNameToggleLabel && (!this.business_name)) {
                this.error.form = true;
                this.error.business_name = 'Business Name is required';
            }
        },

        submit () {  

            this.validate();

            if (!this.error.form) {

                let model =this;
                let orderItem={};
            
                if(model.photoToggleLabel){
                    model.file_types.push('photo');
                    model.files.push(document.getElementById('coporate_photo_file').files[0]);
                }
                    
                if(model.nricNameToggleLabel){
                    
                    model.file_types.push('deep_poll');
                    model.files.push(document.getElementById('deep_poll_file').files[0]);

                    model.file_types.push('nric');
                    model.files.push(document.getElementById('nric_file').files[0])
                }

                orderItem={
                    category_id   : model.categoryId,
                    sub_category_ids:model.subCategoryIds, 
                    nric_name     : model.nric_name,
                    business_name : model.business_name,
                    coporate_photo: model.photoToggleLabel?'Y':'N'
                };

                model.goSubmit(orderItem);
            }
        },
        async goSubmit(orderItem) {  
            store.loading = true;
            let model = this;
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/idtag/orders',orderItem)
            .then(function (res){
                model.orderID=res.data.order;

                if(model.file_types.length)  model.importMultipleFile(model.orderID,model.files,model.file_types);
                //upload files to s3 then save to db
              
                store.loading=false;

                localStorage.removeItem('idtag');
            
                model.router.push('/idtag/payment/'+model.orderID); 

                // localStorage.removeItem('idtag');
                // store.loading = false;
                // model.swal({
                //     icon: 'success',
                //     text: res.data.message,
                //     showCloseButton: true,
                //     showConfirmButton: false
                // });
                // model.router.push({ name: 'IDTAG' });
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        nricNameToggle() {
            let model = this;
            model.nricNameToggleLabel = !model.nricNameToggleLabel;
            if(!model.nricNameToggleLabel){
                model.nric_name =store.idtag.user.nric_name;
            }
        },
        businessNameToggle() { 
            let model = this;
            model.businessNameToggleLabel=!model.businessNameToggleLabel;
            if(!model.businessNameToggleLabel)
            {
                model.business_name =store.idtag.user.business_name;
            }
        },
        photoToggle() {
            let model = this;
            model.photoToggleLabel = !model.photoToggleLabel;
            if(model.photoToggleLabel)
            {
                if(this.info.photo)
                    document.getElementById('profile_photo').src=this.info.photo;
                else
                    document.getElementById('profile_photo').src=store.idtag.user.coporate_photo;  
            } else {
                document.getElementById('profile_photo').src=store.idtag.user.coporate_photo;  
            }
        },
        clear(cl_err) {
            if(!cl_err)
            this.error = [];
            else{
                this.error.form = true;
                this.error={cl_err :''}
            }
        }
    },
    watch: {
        business_name () {
            if (!this.business_name) {
                this.error.form = true;
                this.error.business_name = 'Business Name required';
            } else {
                this.error.form = false;
                this.error.business_name = '';
            }
        },
        nric_name () {
            if (!this.nric_name) {
                this.error.form = true;
                this.error.nric_name = 'NRIC Name required';
            } else {
                this.error.form = false;
                this.error.nric_name = '';
            }
        }
    }
}
</script>

<!-- <style src="@/assets/vue-multiselect.min.css"></style> -->
<style>
    .photo_remark{
       color:#d63939;
       font-size: 13px; 
    }

    .required:before {
        content:" *";
        color: #d63939;
        font-size: 16px;
    }
</style>