<template>
    <VueMultiselect
        selectLabel=""
        v-model="user"
        track-by="code"
        label="name"
        :loading="loading"
        placeholder="Type Username or Name"
        @search-change="onSearch"
        :options="users">
    </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import { ref, inject, getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import { store } from '../store.js';
import axios from 'axios';

export default {
    components: {
        VueMultiselect
    },
    setup() {
        const app = getCurrentInstance();
        const router = useRouter();
        const swal = inject('$swal');
        const user = ref([]);
        const users = ref([]);
        const loading = ref(false);

        return {
            app, store, swal, router,loading,
            user, users
        }
    },
    watch: {
        user() {
            if (this.user) {
                this.showDetails = true;
                store.staff = this.user;
            } else {
                this.showDetails = false;
            }
        }
    },
    methods: {
        onSearch(q) {
            if(q.length >= 4) {
                this.goSearch(q);
            }
        },
        async goSearch(q) {
            let model = this;
            this.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/user/search',{
                params: {
                    role: 'STAFF',
                    q: q
                }
            })
            .then(function (res) {
                model.loading = false;
               let x = 0;
                let option = [];
                for (let row of res.data.users) {
                    option[x] = {
                        code: row.user_id,
                        name: row.user_id + ' - ' + row.user_name + ' ('+ row.user_email +')'
                    };
                    x++;
                }
                model.users = option;
            })
            .catch(function (error) {
                model.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status != 500) {
                    if (error.response.data.errors.slug) {
                        model.error.slug = error.response.data.errors.slug.slice(0,1).shift();
                    }
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    }
	
}
</script>
<style src="@/assets/vue-multiselect.min.css"></style>
<style>
    .multiselect__option--group {
        font-weight: bold;
    }

    .multiselect__option--highlight {
        background:#FCEBEC;
        color:#D63F3E;
    }
</style>