<template>
<PageTitle title="Add IDtag" :breadcrumb="[ { name: 'IDtag', link: '/idtag' }, { name: 'Order Lost' } ]"></PageTitle>
<div class="page-body">
    <div class="container-xl">
        <div class="row row-cards" >
            <div class="col-12">
                <div class="card mb-3">
                   
                    <div class="card-body mb-3">
                        <h4 class="card-title">Report Lost IDtag / Wear & Tear </h4>
                    </div>

                    <div class="card-body mb-3">

                        <div class="row mt-3">
                            <div class="col-lg-12">
                                <div class="form-selectgroup form-selectgroup-boxes">
                                    <label class="form-selectgroup-item flex-fill">

                                        <input type="radio" :value="subCategory[0].id" v-model="subCategoryIds"  class="form-selectgroup-input"  @click="reportLostWTToggle" :checked="reportLostWTLabel">

                                        <div class="form-selectgroup-label d-flex align-items-center p-3">

                                            <div class="me-3">
                                                <span class="form-selectgroup-check"></span>
                                            </div>
                                            <div>
                                                <div class="font-weight-medium">{{ subCategory[0].name }}</div>
                                            </div>
                                            
                                        </div> 

                                    </label>

                                    <label class="form-selectgroup-item flex-fill">

                                        <input type="radio" :value="subCategory[1].id" v-model="subCategoryIds" class="form-selectgroup-input" @click="reportLostWTToggle" >

                                        <div class="form-selectgroup-label d-flex align-items-center p-3">

                                            <div class="me-3">
                                                <span class="form-selectgroup-check"></span>
                                            </div>

                                            <div>
                                                <div class="font-weight-medium">{{ subCategory[1].name }}</div>
                                            </div>

                                        </div>

                                    </label>

                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            
                            <div class="col-lg-12">
                                <div class="form-selectgroup form-selectgroup-boxes" v-if="reportLostWTLabel">

                                    <label class="required">
                                        Upload police report
                                    </label>

                                    <br/><br/>

                                    <input type="file" class="form-control" @change="checkValidation('police_report_file')" name="police_report_file" id="police_report_file" :class="{ 'is-invalid': error.police_report_file}">
                                    
                                    <div class="invalid-feedback">{{ error.police_report_file }}</div> 

                                </div>
                                <div  class="form-selectgroup form-selectgroup-boxes" v-else>
                                    <label class="lbl_remark">
                                        *Please bring along your existing ID tag for 1-1 exchange as a salesperson is not allowed to hold 2 ID tags at the same time
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="card-body mb-3">
                  
                        <div class="d-flex">
                            <!-- <router-link :to="{ name: 'IDTAG' }" class="btn btn-link">
                                <span>Go Back</span>
                            </router-link> -->

                            <router-link :to="{ name: 'IDTAG' }" class="btn btn-primary"  style="margin-right:10px">
                                <i class="ti ti-chevron-left"></i>
                                <span>Go Back</span>
                            </router-link>
                            
                            <button type="submit" class="btn btn-success" @click="submit">
                                <span>Proceed to Payment</span> 
                                <i class="ti ti-chevron-right"></i>
                            </button>
                        </div>
    
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
</template>

    <script>
    import PageTitle from '../../components/PageTitle.vue';
    import { ref, onMounted, inject , getCurrentInstance } from 'vue';
    // import { useRouter, useRoute } from "vue-router";
    import { useRouter } from "vue-router";
    import { store } from '../../store.js';
    import axios from 'axios';
    
    export default {
        components: {
            PageTitle
        },
        setup() {
            const app = getCurrentInstance();
            const router =useRouter();
            // const route = useRoute();
    
            const swal = inject('$swal');
            const form = ref([]);
            const error = ref([]);

            const subCategory = ref([{},{}]);
            const subCategoryIds = ref('');
            const categoryId     = ref('');

            const reportLostWTLabel =ref(true);

            const files      = ref([]);
            const file_types = ref([]);
            const orderID    = ref('');

            async function getSubCateogry(category_id=2) {
        
                categoryId.value=category_id;

                store.loading = true;

                await axios
                .get(process.env.VUE_APP_ENDPOINT+'/idtag/subcategory/'+category_id)
                .then(function (res) {
                    store.loading = false;
           
                    subCategory.value=res.data.data;
            
                    subCategoryIds.value=res.data.data[0].id; //default value 
                    
                })
                .catch(function (error) {
                    console.log(error);
                    store.loading = false;
                    if (error.response && error.response.status == 401) {
                        router.push({ name: 'Logout' });
                    } else if (error.response && error.response.status == 422) {
                        swal({
                            icon: 'error',
                            text: error.response.data.message,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }else if (error.response && error.response.status == 403) {
                        // router.push({ name: 'IDTAGManage' });
                        router.push({ name: 'Home' });
                    } else {
                        swal({
                            icon: 'error',
                            text: app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            }
            onMounted(() => {
                getSubCateogry(2);       
            });
    
            return { store, swal, router, form, error,subCategory,subCategoryIds,categoryId,reportLostWTLabel,files,file_types,orderID}
        },
        methods: {
            checkValidation(error_class){
                this.error.form = false;
                this.error[error_class]='';
            },
            async importPoliceFile(file) { //not use 
                store.loading = true;
                this.showUploadBtn = false;
                let model = this;

                var formData = new FormData();
                formData.append("file",file);
                formData.append("type",'police_file');
                await axios
                .post(process.env.VUE_APP_ENDPOINT+'/idtag/upload-file',formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(function (res) {
                    console.log('done upload file');
                    console.log(res.data);

                    store.loading =false;
                    store.idtag.files.police_report_path = res.data.path;
                    store.idtag.files.police_report = res.data.filename;
                })
                .catch(function (error) {
                    store.loading = false;
                    if (error.response && error.response.status == 401) {
                        model.router.push({ name: 'Logout' });
                    } else if (error.response && error.response.status != 500) {
                        model.swal({
                            icon: 'error',
                            text: error.response.data.message,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    } else {
                        model.swal({
                            icon: 'error',
                            text: model.app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            },
            async importMultipleFile(order_id,files,file_types){

                store.loading = true;
                let model =this;

                var formData = new FormData();
                formData.append("order_id", order_id);

                for (var i = 0; i < file_types.length; i++) {
                    formData.append("files[]", files[i]);
                    formData.append("file_types[]",file_types[i])
                }

                await axios
                .post(process.env.VUE_APP_ENDPOINT+'/idtag/multipleFileUpload',formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(function (res) {
                    console.log(res.data); //can store file name. store.idtag if required.
                    store.loading = false;
                    // let orderID   = model.orderID;
                    // localStorage.removeItem('idtag');
                    // model.router.push('/idtag/payment/'+orderID);
                })
                .catch(function (error) {
                    store.loading = false;
                    if (error.response && error.response.status == 401) {
                        model.router.push({ name: 'Logout' });
                    } else if (error.response && error.response.status != 500) {
                        model.swal({
                            icon: 'error',
                            text: error.response.data.message,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    } else {
                        model.swal({
                            icon: 'error',
                            text: model.app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            },
            submit() {  

                this.error.form = false;
                this.clear();
    
                if (this.reportLostWTLabel) {
                    if(!document.getElementById('police_report_file').files.length){
                        this.error.form = true;
                        this.error.police_report_file = 'Police report file is required';
                    }
                }
             
                if (!this.error.form) {

                    if(this.reportLostWTLabel){
                        this.file_types.push('police_file');
                        this.files.push(document.getElementById('police_report_file').files[0]);
                    }

                    let orderItem={
                        category_id      :this.categoryId,
                        sub_category_ids :[this.subCategoryIds]
                    };

                    this.goSubmit(orderItem);
                }

            },
            async goSubmit(orderItem) {  
                store.loading = true;
                let model = this;
                await axios
                .post(process.env.VUE_APP_ENDPOINT+'/idtag/orders',orderItem)
                .then(function (res){

                    console.log(res.data);

                    model.orderID=res.data.order;

                    if(model.file_types[0]) model.importMultipleFile(model.orderID,model.files,model.file_types);
                    //save file to s3 and also save to db;

                    localStorage.removeItem('idtag');

                    model.router.push('/idtag/payment/'+model.orderID);

                })
                .catch(function (error) {
                    console.log(error);
                    store.loading = false;
                    if (error.response && error.response.status == 401) {
                        model.router.push({ name: 'Logout' });
                    } else if (error.response && error.response.status == 422) {
                        model.swal({
                            icon: 'error',
                            text: error.response.data.message,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    } else {
                        model.swal({
                            icon: 'error',
                            text: model.app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            },
            reportLostWTToggle(){
                let model = this;
                model.reportLostWTLabel = !model.reportLostWTLabel;
                // console.log(this.subCategoryIds);
            },
            clear() {
                this.error = [];
            }
        },
        watch: {   
            police_report_file () {
                if (!this.police_report_file) {
                    this.error.form = true;
                    this.error.police_report_file = 'Police Report File is required';
                } else {
                    this.error.form = false;
                    this.error.police_report_file = '';
                }
            },
        }
    }
    </script>

    <style>
    .lbl_remark{
        /* color:red; */
        color: #d63939;
    }
    .required:before {
        content:" *";
        color: #d63939;
        font-size: 16px;
    }
    </style>