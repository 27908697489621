import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/global.css';
import './assets/style.css';
import './assets/tabler/tabler.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Popper from "vue3-popper";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import VueCountdown from '@chenfengyuan/vue-countdown';
import axios from 'axios';
import '@/assets/tabler-icons.min.css';

let app = createApp(App)
app
    .use(router)
    .use(VueSweetalert2)
    .component("Popper", Popper)
    .component(VueCountdown.name, VueCountdown)
    .mount('#app');
app.config.globalProperties.axios=axios;
app.config.globalProperties.error500 = 'Something went wrong. Please try again or contact the system administrator';