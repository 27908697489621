<template>
    &nbsp;
</template>

<script>
import { onMounted } from 'vue';
import { useRouter } from "vue-router";

export default {
    components: {
    },
    setup() {
        const router = useRouter();

        onMounted(() => {
            window.location.href = process.env.VUE_APP_MAINPAGE;
        });

        return { router };
    }
}
</script>