<template>
    <div class="card mb-3">
        <div class="card-body">
            <h3 class="card-title">Tracking History</h3>
            <ul class="steps steps-vertical">
                <li class="step-item" :class="{ 'active': (lastStatus == 'PENDING' || lastStatus == 'PAID') }">
                    <div class="h4 m-0">Order Created</div>
                    <div class="text-muted" >{{ timeFormat(orderActions.PENDING) }}</div>
                </li>
               
                <li class="step-item" :class="{ 'active': lastStatus == 'CANCELLED' }" v-if="lastStatus == 'CANCELLED'">
                    <div class="h4 m-0">Cancelled</div>
                    <div class="text-muted">{{ timeFormat(orderActions.CANCELLED) }}&nbsp;</div>
                </li>

                <li class="step-item" :class="{ 'active': lastStatus == 'INCOMPLETE' }" v-if="lastStatus == 'INCOMPLETE'">
                    <div class="h4 m-0">Incomplete</div>
                    <div class="text-muted">{{ timeFormat(orderActions.INCOMPLETE) }}&nbsp;</div>
                </li>

                <li class="step-item" :class="{ 'active': lastStatus == 'APPROVED' }">
                    <div class="h4 m-0">Approved</div>
                    <div class="text-muted">{{ timeFormat(orderActions.APPROVED) }}&nbsp;</div>
                </li>
                <!-- <li class="step-item" :class="{ 'active': lastStatus == 'PROCESSING' }">
                    <div class="h4 m-0">Processing</div>
                    <div class="text-muted">{{ timeFormat(orderActions.PROCESSING) }}&nbsp;</div>
                </li> -->
                <li class="step-item" :class="{ 'active': lastStatus == 'READY FOR COLLECTION' }">
                    <div class="h4 m-0">Ready for Collection</div>
                    <div class="text-muted">{{ timeFormat(orderActions.READY) }}&nbsp;</div>
                </li>
                <li class="step-item" :class="{ 'active': lastStatus == 'COLLECTED' }">
                    <div class="h4 m-0">Collected</div>
                    <div class="text-muted">{{ timeFormat(orderActions.COLLECTED) }}&nbsp;</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { timeFormat } from '@/helpers/me';

export default {
	props: ['actions'],
    data(){
        const router = useRouter();
        const route = useRoute();
        const lastStatus = ref('');
        const orderActions = ref([]);

        return {
            router, route, lastStatus, orderActions, timeFormat
        }
    },
    watch: {
        actions() {
            let model = this;
            this.actions.forEach(function(row) {
                model.lastStatus = row.order_status;
                switch (row.order_status) {
                    case 'PENDING':
                        model.orderActions.PENDING = row.created_at;
                        break;
                    case 'INCOMPLETE':
                        model.orderActions.INCOMPLETE = row.created_at;
                        break;
                    case 'APPROVED':
                        model.orderActions.APPROVED = row.created_at;
                        break;
                    case 'READY FOR COLLECTION':
                        model.orderActions.READY = row.created_at;
                        break;
                    case 'COLLECTED':
                        model.orderActions.COLLECTED = row.created_at;
                        break;
                    case 'CANCELLED':
                        model.orderActions.CANCELLED = row.created_at;
                        break;
                }
            });
        }
    }
}
</script>