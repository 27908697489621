<template>
    <PageTitle title="Manage IDtag Orders" :breadcrumb="[ { name: 'Manage IDtag Orders' } ]">
        <span>
            <span>
                <router-link :to="{  name: 'IDTAGMassExport' }"  class="btn btn-success">
                    <!-- <i class="ti ti-export"></i>  -->
                    <span>Mass Export</span>
                </router-link>
            </span>
        </span>
    </PageTitle>
    <div class="page-body">
        <div class="container-xl">
            <div class="row row-cards">
                <div class="col-12">
                    <div class="card mb-3">
                        <div class="card-body border-bottom py-3">
                            <div class="d-flex">
                                <div class="text-muted">
                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="ti ti-filter"></i> <span>Status</span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item" href="#" :class="{ 'active': route.query.status == '' }"  @click="filterStatus('')">All</a></li>
                                            <!-- <li><a class="dropdown-item" :class="{ 'active': route.query.status == 2 }" href="#" @click="filterStatus(2)">Pending</a></li> -->
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 4 }" href="#" @click="filterStatus(4)">Paid</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 5 }" href="#" @click="filterStatus(5)">Approved</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 8 }" href="#" @click="filterStatus(8)">Ready for Collection</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 9 }" href="#" @click="filterStatus(9)">Collected</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 6 }" href="#" @click="filterStatus(6)">Cancelled</a></li> 
                                        </ul>
                                    </div>
                                </div> 
                                <div class="ms-auto text-muted">
                                    <div class="input-icon">
                                    <input type="text" v-model="q" class="form-control form-control-rounded" placeholder="Search…" @keyup.enter="makeSearch">
                                        <span class="input-icon-addon">
                                            <i class="ti ti-search"></i>
                                        </span>
                                    </div>
                                </div> 
                            </div>
                        </div> 
                        <div class="table-responsive">
                            <table class="table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th class="w-5">Order No</th>
                                        <th>Associate Code</th>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Order At</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                    <tbody>
                                    <tr v-for="(row, index) in orders.data" :key="index">
                                        <td class="text-center">
                                            <router-link :to="{  name: 'IDTAGManageDetails', params: { id: row.id } }">
                                                {{ row.id }}
                                            </router-link>
                                        </td>
                                        <td>{{ row.order_by }}</td>
                                        <td>{{ row.name }}</td>
                                        <td>{{ row.category_name }}</td>
                                        <td>{{ row.amount_text }}</td>    
                                        <td><OrderStatus :orderStatus="row.status"></OrderStatus></td>
                                        <td>
                                            {{ timeFormat(row.created_at) }}
                                        </td>
                                       
                                        <td>
                                            <div class="dropdown">

                                                <a class="btn btn-sm btn-ghost-red dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ti ti-dots"></i>
                                                </a>

                                                <ul class="dropdown-menu">

                                                    <li v-if="row.status == 'APPROVED' || row.status == 'READY FOR COLLECTION'"><h6 class="dropdown-header">Actions</h6></li>
                                                

                                                    <li v-if="row.status == 'APPROVED'">
                                                        <button class="dropdown-item" @click="updateStatus(row.id,8)">
                                                            Ready for Collection
                                                        </button>
                                                    </li>

                                                    <li v-if="row.status == 'READY FOR COLLECTION'">
                                                        <button class="dropdown-item" @click="updateStatus(row.id,9)">
                                                            Collected
                                                        </button>
                                                    </li>

                                        
                                                    <li v-if="row.status == 'APPROVED' || row.status == 'READY FOR COLLECTION'"><hr class="dropdown-divider"></li>

                                                    <li><h6 class="dropdown-header">Connections</h6></li>

                                                    <li>
                                                        <router-link class="dropdown-item" :to="{ name: 'IDTAGManageDetails', params: { id: row.id } }">
                                                            View Details <i class="ti ti-arrow-right"></i>
                                                        </router-link>
                                                    </li>

                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer paginationBox">
                            <v-pagination
                                v-model="meta.current_page"
                                :pages="meta.last_page"
                                :range-size="1"
                                active-color="#DCEDFF"
                                @update:modelValue="goSearch"
                            />
                            <div class="item_counter">1 of {{ meta.last_page }} ({{ meta.total }} items)</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import PageTitle from '../../components/PageTitle.vue';
import OrderStatus from './components/OrderStatus.vue';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import VPagination from "@hennge/vue3-pagination";
import axios from 'axios';
import { store } from '../../store.js';
import { timeFormat } from '@/helpers/me';

export default {
    components: {
        PageTitle,
        OrderStatus,
        VPagination
    },
    setup(){
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const orders = ref([]);
        const meta = ref([]);
        const select = ref(null);
        const options =  ['list', 'of', 'options'];
        const q = ref([]);
        const search = ref([]);
        const form = ref([]);
        const checkAll = ref(false);

        async function getOrders() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/idtag/manage/orders',{ 
                params: route.query
            })
            .then(function (res) {
                store.loading = false;
                orders.value = res.data
                meta.value = res.data.meta;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else if (error.response && error.response.status == 403) {
                    // router.push({ name: 'IDTAG' });
                    router.push({ name: 'Home' });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        onMounted(() => {
            if (!localStorage.getItem('authToken')) {
                router.push({ name: 'Logout' });
                return false;
            }
            getOrders();
        });

        return { router,route, swal, orders, store, meta, select, options, timeFormat, q, search, form, checkAll };
    },
    methods: {
        
        async goSearch() { 
            store.loading = true;
            let query = {
                page: this.meta.current_page,
                q: this.q,
                status: this.search.status
            }
            let model = this;
            this.router.push({ name: 'IDTAGManage', query: query });
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/idtag/manage/orders',{ 
                params: query
            })
            .then(function (res) {
                store.loading = false;
                model.orders = res.data;
                model.meta = res.data.meta;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        filterStatus(status) {
            this.meta.current_page = 1;
            this.search.status = status;
            this.goSearch();
        },
        uncheckAll() {
            this.form.checkCount = 0;
            this.checkAll = false;
            let checkboxes = document.getElementsByClassName('check');
            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
        },
        makeSearch() {
            this.meta.current_page = 1;
            this.search.status = '';
            this.goSearch();
        },
       
        async updateStatus(order_id,status_id) {
            if(status_id == 8) // ready for collection
            {
                await this.updateStatusAPI(order_id,status_id);
                window.location.reload();

            } else if(status_id == 9) // collected
            {
                await this.updateStatusAPI(order_id,status_id);
                window.location.reload();
            } 
        },
        async updateStatusAPI(order_id,status_id) {
            store.loading = true;
            let model = this;
            await axios
            .put(process.env.VUE_APP_ENDPOINT+'/idtag/manage/orders/'+order_id,{
                order_status_id: status_id
            })
            .then(function (res) {
                store.loading = false;
            
                model.swal({
                    icon: 'success',
                    text: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                });
           
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
    }
}

</script>

<style scoped>
.dropdown-toggle:after {
    display: none;
}
.dropdown-toggle.no-arrow-down:after {
    display: none;
}
</style>