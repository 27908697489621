<template>
    <div class="namecard agent ts5">
        <div class="preview" :style="'background-image: url('+ namecard.template.front_bg +')'">
            <div class="row">
                <div class="row box-info-left">
                    <h1 class="name">{{ namecard.user.name }} {{ namecard.user.namecn }}</h1>
                    <h3 class="qualification">{{ namecard.user.position }} &nbsp; {{ namecard.user.position_cn }}</h3>
                    <h3 class="phone">+65 {{ namecard.user.phone }}</h3>
                    <h3 class="email">{{ namecard.user.email }} &nbsp;</h3>
                </div>
            </div>
        </div>
        <div class="preview-back" :style="'background-image: url('+ namecard.template.back_bg +');'">
        </div>
    </div>
</template>

<script>
import { store } from '../../../store.js';
import { ref, onMounted } from 'vue';

export default {
    components: {
    },
    setup() {
        const namecard = ref(store.namecard);
        onMounted(() => {
        });

        return { store, namecard };
    }
}
</script>
<style scoped>
    .ts5{
        font-family: 'Gotham' !important;
    }
    .name, .qualification{
        font-family: 'Gotham Bold' !important;
    }
    .namecard.agent .box-info-left {
        margin-top: 15px;
        margin-left: 250px;
    }

    .phone {
        margin-top: 20px;
    }
</style>