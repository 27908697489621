<template>
    <div>
        <div v-if="orderStatus == 'PENDING'">
            <span class="status status-dark"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'APPROVED'">
            <span class="status status-yellow"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'PAID'">
            <span class="status status-green"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'PROCESSING'">
            <span class="status status-blue"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'ARRIVED'">
            <span class="status status-purple"> {{orderStatus}} HQ</span>
        </div>
        <div v-else-if="orderStatus == 'READY'">
            <span class="status status-orange"> READY FOR COLLECTION</span>
        </div>
        <div v-else-if="orderStatus == 'COLLECTED'">
            <span class="status status-cyan"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'REJECTED'">
            <span class="status status-red"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'CANCELLED'">
            <span class="status status-red"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'PENDING FOR APPROVAL'">
            <span class="status status-dark"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'PENDING FOR PAYMENT'">
            <span class="status status-dark"> {{orderStatus}}</span>
        </div>
        <div v-else>
            <span class="status status-dark"> {{orderStatus}}</span>
        </div>
    </div>
</template>

<script>

export default {
	props: ['orderStatus']
}
</script>