<template>
    <PageTitle title="IDtag Details" :breadcrumb="[ { name: 'IDtag', link: '/idtag/manage' }, { name: 'Details' } ]">
    </PageTitle>

    <div class="page-body">
        <div class="container-xl">
            <div class="row row-cards">
                <div class="col-lg-8">
                    <div class="card mb-3">
                        <div class="card-header">
                            <div class="card-title">Details</div>
                        </div>
                        <div class="card-body">
                            <div class="datagrid">
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Submitted By</div>
                                    <div class="datagrid-content">
                                    <div class="d-flex align-items-center">
                                        <span class="avatar avatar-xs me-2 avatar-rounded" :style="{ backgroundImage: 'url(https://developer.era.com.sg/agent/profile_photo/'+order.order_by+')' }"></span>
                                        {{ order.name }}
                                    </div>
                                    </div>
                                </div>
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Associate Code</div>
                                    <div class="datagrid-content" id="associate_code">{{ order.order_by }}</div>
                                </div>
                              
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Order ID</div>
                                    <div class="datagrid-content">{{ order.id }}</div>
                                </div>
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Payment Status</div>
                                    <div class="datagrid-content">
                                        <div v-if="order.payment_status">
                                            <span class="status status-green" v-if="order.payment_status == 'COFM'">PAID</span>
                                            <span class="status status-dark" v-else-if="order.payment_status == 'PEND'">PENDING</span>
                                            <span class="status status-dark" v-else-if="order.payment_status == 'RFND'">REFUND</span>
                                            <span class="status status-dark" v-else>{{order.payment_status}}</span>
                                        </div>
                                    </div>
                                </div>
                
                                <div class="datagrid-item">
                                    <div class="datagrid-title" v-if="order.payment_method == 'POINTS'">Points Redeem</div>
                                    <div class="datagrid-title" v-else>Amount</div>
                                    <div v-if="order.payment_status == 'COFM'">
                                        <div class="datagrid-content">{{ order.payment_amount }}</div>
                                    </div>
                                </div>

                                <div class="datagrid-item">
                                    <div class="datagrid-title">Payment Reference No.</div>
                                    <div class="datagrid-content" v-if="order.payment_method == 'POINTS' && order.payment_status=='COFM'">{{ order.external_ref_id }}</div>
                                    <div class="datagrid-content" v-if="order.payment_method == 'PAYNOW' && order.payment_status=='COFM'">{{ order.payment_id }}</div>
                                </div> 

                                <div class="datagrid-item">
                                    <div class="datagrid-title">Payment Method</div>
                                    <div class="datagrid-content" v-if="order.payment_method == 'POINTS' && order.payment_status=='COFM'">
                                        ERA Points
                                    </div>
                                    <div class="datagrid-content" v-if="order.payment_method == 'PAYNOW' && order.payment_status=='COFM'">
                                        {{ order.payment_method }}
                                    </div>
                                </div>

                                <div class="datagrid-item">
                                    <div class="datagrid-title">Description</div>
                                    <div class="datagrid-content">
                                       {{ order.sub_category_str }}
                                    </div>
                                </div>

                                <div class="datagrid-item">
                                    <div class="datagrid-title">Submitted Date</div>
                                    <div class="datagrid-content">
                                        {{ timeFormat(order.created_at) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-3">
                        <div class="card-header">
                            <div class="card-title">IDtag Preview</div>
                        </div>

                        <div class="card-body overflow-auto">
                            <CustomTa1 :business_name="business_name" :nric_name="nric_name" :coporate_photo="coporate_photo" :cea_regno="cea_regno" />
                        </div>

                        <div class="card-footer">
                            <div class="text-center" v-if="order.payment_status=='COFM'">

                                <button class="btn btn-cyan" @click="downloadFolder" v-if="store.idtag.files.length && order.status!='CANCELLED'">
                                    <span>Download Documents</span>
                                    <!-- <i class="ti ti-download"></i> -->
                                </button>
                    
                                <button @click="exportPreview" class="btn btn-secondary" v-if="order.status=='APPROVED' || order.status=='READY FOR COLLECTION' || order.status=='COLLECTED'">
                                    <span>Export as JPG </span>
                                    <i class="ti ti-file-export"></i>
                                </button>
                            
                                <button class="btn btn-primary" v-if="order.status=='APPROVED'" @click="updateStatus(8)">
                                    <span>Ready for Collection</span>
                                    <i class="ti ti-chevron-right"></i>
                                </button>
                            
                                <button class="btn btn-info" v-if="order.status=='READY FOR COLLECTION'" @click="updateStatus(9)">
                                    <span>Collected</span>
                                    <i class="ti ti-chevron-right"></i>
                                </button>
                            </div>
                            <div class="text-center" v-else>
                                <button class="btn btn-danger" @click="updateStatus(6)" v-if="order.status!='CANCELLED'">
                                    <i class="ti ti-x"></i> <span>Cancel Order</span>
                                </button>
                            </div>
                        </div>   
                    </div>
                </div>

                <div class="col-lg-4">
                    
                    <OrderTracking :actions="actions"/>

                    <div class="card particular" v-if="store.idtag_detail.categoryID==1 && (order.status=='PENDING' || order.status=='PAID' || order.status=='INCOMPLETE') && (order.payment_status=='COFM')">
                        <div class="card-header">
                            <div class="card-title">
                                <h1 class="card-title">Edit Particular / Photo</h1>
                            </div>
                        </div>
                        <div class="card-body mb-4">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="mb-3">
                                        <label class="row">
                                            <span class="col">Change Corporate Photo</span>
                                            <span class="col">
                                                <label class="form-check form-switch">
                                                    <input class="form-check-input" :value=1 v-model="subCategoryIds" type="checkbox" @change="photoToggle" :checked="photoToggleLabel">
                                                    <span class="form-check-label">{{photoToggleLabel ? "  Yes" : "  No"}}</span>
                                                </label>
                                            </span>  
                                        </label>

                                        <div v-if="photoToggleLabel">
                                            
                                            <input type="file" id="coporate_photo_file" name="coporate_photo_file" class="form-control" @change="showPreview()"  :class="{ 'is-invalid': error.coporate_photo_file}">
                                    
                                            <div class="invalid-feedback">{{ error.coporate_photo_file }}</div>
                                        
                                            <div class="coporate_photo_path" v-if="store.idtag_docs.photo">
                                                <a :href="store.idtag_docs.photo" target="_blank">Corporate Photo File</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="row">
                                            <span class="col">Change NRIC Name</span> 
                                            <span class="col">
                                                <label class="form-check form-switch">
                                                    <input class="form-check-input" :value=2 v-model="subCategoryIds"  type="checkbox" @change="nricNameToggle" :checked="nricNameToggleLabel"  >
                                                    <span class="form-check-label">{{nricNameToggleLabel ? "  Yes" : "  No"}}</span>
                                                </label>
                                            </span>  
                                        </label>

                                        <div v-if="nricNameToggleLabel">

                                            <input type="text" placeholder="type" class="form-control"  v-model="nric_name"  :class="{ 'is-invalid': error.nric_name}">
                                    
                                            <div class="invalid-feedback">{{ error.nric_name }}</div>

                                            <br/>
                                            
                                            <label class="required">Upload a deed poll </label>

                                            <br/><br/>

                                            <input type="file" class="form-control" name="deep_poll_file" @change="checkValidation('deep_poll_file')" id="deep_poll_file" :class="{ 'is-invalid': error.deep_poll_file}">

                                            <div class="invalid-feedback">{{ error.deep_poll_file }}</div>
                                          

                                            <div class="deep_poll_path" v-if="store.idtag_docs.deep_poll">
                                                <a :href="store.idtag_docs.deep_poll" target="_blank">Deep poll File</a>
                                            </div>

                                            <br/>

                                            <label class="required">Upload NRIC (front view)</label>
                                        
                                            <br/><br/>

                                            <input type="file" class="form-control" name="nric_file" id="nric_file" @change="checkValidation('nric_file')" :class="{ 'is-invalid': error.nric_file}">
                                    
                                            <div class="invalid-feedback">{{ error.nric_file }}</div> 

                                            <div class="nric_path" v-if="store.idtag_docs.nric">
                                                <a :href="store.idtag_docs.nric" target="_blank">NRIC File </a>
                                            </div>
                                          
                                        </div>

                                    </div>

                                    <div class="mb-3">
                                        <label class="row">
                                            <span class="col">Change Business Name</span>   <!--  Business Testing -->
                                            <span class="col">
                                                <label class="form-check form-switch">
                                                <input class="form-check-input" :value=3 type="checkbox" v-model="subCategoryIds" @change="businessNameToggle" :checked="businessNameToggleLabel">
                                                <span class="form-check-label">{{businessNameToggleLabel ? "  Yes" : "  No"}}</span>
                                                </label>
                                            </span>
                                        </label>  

                                        <div v-if="businessNameToggleLabel">
                                            <input type="text" placeholder="type"  class="form-control" v-model="business_name" :class="{ 'is-invalid': error.business_name}">
                                            <div class="invalid-feedback">{{ error.business_name }}</div>
                                        </div> 
                                    </div>

                                    <div class="mb-3" v-if="order.status!='INCOMPLETE'">
                                        <div class="col">
                                            <textarea cols="47" rows="5" class="form-control" @change="checkRemarkValidation('incomplete_remark')"  placeholder="Remark for incomplete information" v-model="incomplete_remark" id="incomplete_remark" :class="{ 'is-invalid': error.incomplete_remark}"></textarea>
                                            <div class="invalid-feedback">{{ error.incomplete_remark }}</div> 
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                            <div class="row align-items-center"> 
                                <div class="text-left">
                                    <button type="submit" class="btn btn-primary" @click="updateOrder()" :disabled="(photoToggleLabel || nricNameToggleLabel || businessNameToggleLabel)?disabled:'' " >
                                        <span>Update Order</span> 
                                        <i class="ti ti-chevron-right"></i>
                                    </button>

                                    <button class="btn btn-warning"  v-if="order.status!='INCOMPLETE'" @click="updateStatus(10)" >
                                        <span>Incomplete</span>
                                        <!-- <i class="ti ti-arrow-back"></i> -->
                                    </button>
                        
                                    <button class="btn btn-danger" @click="updateStatus(6)" v-if="(order.status !='APPROVED' && order.status !='READY FOR COLLECTION' && order.status !='COLLECTED' && order.status !='CANCELLED')">
                                        <i class="ti ti-x"></i> <span>Cancel Order</span>
                                    </button>

                                    <button class="btn btn-success" @click="updateStatus(5)" v-if="order.status!='APPROVED' && order.status!='READY FOR COLLECTION' && order.status!='COLLECTED' && order.status!='CANCELLED' && order.payment_status=='COFM'">
                                        <span>Approved</span>
                                        <i class="ti ti-check"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card reportlost mt-3" v-if="store.idtag_detail.categoryID==2 && (order.status=='PENDING' || order.status=='PAID' || order.status=='INCOMPLETE') && (order.payment_status=='COFM')">
                        <div class="card-header">
                            <div class="card-title">
                                <h1 class="card-title">Edit Report Lost IDtag / Wear & Tear (Damaged) </h1>
                            </div>
                        </div>
                   
                        <div class="card-body mb-3">
                            <div class="row">
                                <!-- <div class="col-lg-12"> -->
                                    <div class="form-selectgroup form-selectgroup-boxes">
                                        <label class="form-selectgroup-item flex-fill col-lg-5">
                                            <input type="radio" :value=4 v-model="subCategoryId" class="form-selectgroup-input" @click="reportLostWTToggle" :checked="subCategoryId==4" >
                                            <div class="form-selectgroup-label d-flex align-items-center p-3">
                                                <div class="me-3">
                                                    <span class="form-selectgroup-check"></span>
                                                </div>
                                                <div>
                                                    <div class="font-weight-medium">Report Lost IDtag</div>
                                                </div>
                                            </div> 
                                        </label>

                                        <label class="form-selectgroup-item flex-fill col-lg-5">
                                            <input type="radio" :value=5 v-model="subCategoryId" class="form-selectgroup-input" @click="reportLostWTToggle" :checked="subCategoryId==5">
                                            <div class="form-selectgroup-label d-flex align-items-center p-3">
                                                <div class="me-3">
                                                    <span class="form-selectgroup-check"></span>
                                                </div>
                                                <div>
                                                    <div class="font-weight-medium">Wear & Tear (Damaged)</div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                <!-- </div> -->
                            </div>

                            <div class="row mt-3">
                                <!-- <div class="col-lg-12"> -->
                                <div class="form-selectgroup form-selectgroup-boxes" v-if="subCategoryId==4">
                                    <br/>
                                    <label class="required">
                                        Upload police report 
                                    </label>
                                    
                                    <br/><br/>

                                    <input type="file" class="form-control"  name="police_file" id="police_file" @change="checkValidation('police_file')" :class="{ 'is-invalid': error.police_file}">
                                    
                                    <div class="invalid-feedback">{{ error.police_file }}</div> 

                                    <div class="police_file" v-if="store.idtag_docs.police_file">
                                        <a :href="store.idtag_docs.police_file" target="_blank">Police File</a>
                                    </div>
                                </div>

                                <div class="form-selectgroup form-selectgroup-boxes" v-else>
                                    <label class="photo_remark">
                                        *Please bring along your existing ID tag for 1-1 exchange as a salesperson is not allowed to hold 2 IDtag at the same time
                                    </label>
                                </div>
                                <!-- </div> -->
                            </div>

                            <div class="row mt-3">
                                <div>
                                    <textarea cols="47" class="form-control" @change="checkRemarkValidation('report_lost_incomplete_remark')" v-model="incomplete_remark"  rows="5" placeholder="Remark for incomplete information" id="report_lost_incomplete_remark" :class="{ 'is-invalid': error.report_lost_incomplete_remark}"></textarea>
                                    <span class="invalid-feedback">{{ error.report_lost_incomplete_remark }}</span> 
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="text-left">
                                    <button type="submit" class="btn btn-primary" @click="updateOrder()" :disabled="store.loading">
                                        <span>Update Order</span> 
                                        <i class="ti ti-chevron-right"></i>
                                    </button>
                                    <button class="btn btn-warning" @click="updateInCompleteStatus(10)" >
                                        <!-- <i class="ti ti-arrow-back"></i> -->
                                        <span>Incomplete</span>
                                    </button>
                                    <button class="btn btn-danger" @click="updateStatus(6)" v-if="(order.status !='APPROVED' && order.status !='READY FOR COLLECTION' && order.status !='COLLECTED' && order.status !='CANCELLED')">
                                        <i class="ti ti-x"></i> <span>Cancel Order</span>
                                    </button>
                                    <button class="btn btn-success" @click="updateStatus(5)"  v-if="order.status!='APPROVED' && order.status!='READY FOR COLLECTION' && order.status!='COLLECTED' && order.status!='CANCELLED' && order.payment_status=='COFM'">
                                        <span>Approved</span>
                                        <i class="ti ti-check"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import PageTitle from '../../components/PageTitle.vue';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import { store } from '../../store.js';
import OrderTracking from './components/OrderTracking.vue';
import { timeFormat } from '@/helpers/me';

import CustomTa1 from './components/CustomTa1.vue';
import html2canvas from 'html2canvas';
export default {
    components: {
        PageTitle,
        OrderTracking,
        CustomTa1
    },
    setup(){
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const order = ref([]);
        const meta = ref([]);
        const actions = ref([]);
        const templates = ref([]);
        const ranknamecnToggle = ref();
        const name = ref('');
        const namenoInitial = ref('');
        const namewithInitial = ref('');
        const nameToggleLabel = ref();
        const namecn = ref('');
        const positioncn = ref('');
        const social = ref([]);
        const email  = ref('');
        const error  = ref([]);

    
        const orderDetail   =ref([]);

        const photoToggleLabel = ref(false);
        const nricNameToggleLabel = ref(false);
        const businessNameToggleLabel = ref(false);
        const reportLostWTLabel=ref('');
    
        const business_name  = ref('');
        const nric_name      = ref('');
        const coporate_photo = ref('');
        const cea_regno      = ref('');

        const subCategoryIds = ref([]);// just for checkbox for change particular
        const subCategoryId  = ref('');// for radio button report lost & wear tear
        const files      = ref([]); 
        const file_types = ref([]);
        const orderID    = ref('');
        const info       = ref([]);

        const incomplete_remark=ref('');
        const assc_code      = ref('');
        async function getOrder() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/idtag/manage/orders/'+route.params.id)
            .then(function (res) {

                store.idtag.user={
                    business_name:res.data.data.business_name,
                    nric_name    :res.data.data.nric_name,
                    coporate_photo:res.data.data.coporate_photo
                }

                if(res.data.data.nricDocs){
                    store.idtag_docs=[];//store.idtag.files=[];
                    res.data.data.nricDocs.forEach( (doc)=> {
                        if(doc.path){
                            store.idtag_docs[doc.type]=doc.path;//show previous files,photos for edit 
                            store.idtag.files.push(doc.path) //download all files 
                        }
                    });
                }

                coporate_photo.value = store.idtag_docs.photo?store.idtag_docs.photo:res.data.data.coporate_photo;
                business_name.value  = res.data.data.business_name;
                nric_name.value      = res.data.data.nric_name;
                cea_regno.value      = res.data.data.cea_regno;

                actions.value        = res.data.data.statusHistory;
                order.value          = res.data.data;
                orderDetail.value    = res.data.data.orderDetail;//to bind defaul
                assc_code.value      = res.data.data.order_by;
                store.idtag_detail.categoryID  = res.data.data.category_id;//flag for report lost or change particular 
                
                if(res.data.data.orderDetail){

                    res.data.data.orderDetail.forEach( (order)=> {

                        if(order.sub_category_id==1) photoToggleLabel.value=true;
                        if(order.sub_category_id==2) nricNameToggleLabel.value=true;
                        if(order.sub_category_id==3) businessNameToggleLabel.value=true;
                    
                        if(order.sub_category_id==4) subCategoryId.value=4;
                        if(order.sub_category_id==5) subCategoryId.value=5;

                        subCategoryIds.value.push(order.sub_category_id);
                    });
                }

                store.loading = false;

            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                     router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 404) {
                    //  router.push({ name: 'IDTAGManage' });
                    router.push({ name: 'Home' });
                } else if (error.response && error.response.status == 403) {
                    // router.push({ name: 'IDTAG' });
                    router.push({ name: 'Home' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
        onMounted(() => {
            if (!localStorage.getItem('authToken')) {
                router.push({ name: 'Logout' });
                return false;
            }
            getOrder();
        });

        return { store, router, route, templates, swal, order, email, error, meta, actions, social, timeFormat, ranknamecnToggle, namenoInitial, namewithInitial, name, namecn, nameToggleLabel, positioncn, business_name,nric_name,coporate_photo,cea_regno,orderDetail,nricNameToggleLabel,businessNameToggleLabel,photoToggleLabel,subCategoryIds,info,orderID,files,file_types,reportLostWTLabel,subCategoryId,incomplete_remark,assc_code};
    },
    methods: {
        async updateInCompleteStatus(status_id) {//for reportlost
            let model = this;
            let remark=document.getElementById('report_lost_incomplete_remark').value;
            
            if(!remark){
                this.error.form = true;
                this.error.report_lost_incomplete_remark = 'Incomplete Information is required';
                return;
            }
            
            if(status_id == 10)
            {
                model.swal({
                    title: 'Are you sure you want to proceed?',
                    // text: "you want to proceed",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then((result) => {
                    let remark=document.getElementById('report_lost_incomplete_remark').value;
                    if (result.isConfirmed) {
                       model.updateStatusAPI(10,remark);
                    }
                })
            }
        },
        async updateStatus (status_id) {
            let model = this;
            if(status_id == 10) //Incomplete
            {
                this.error.form = false;
                this.clear();
                
                if(!document.getElementById('incomplete_remark').value){
                    this.error.form = true;
                    this.error.incomplete_remark = 'Incomplete Information is required';
                    return;
                }

                model.swal({
                    title: 'Are you sure you want to proceed?',
                    // text: "you want to proceed",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then((result) => {
                    let remark=document.getElementById('incomplete_remark').value;
                    if (result.isConfirmed) {
                        model.updateStatusAPI(10,remark);
                    }
                })
            }else if(status_id == 6) //cancel 
            {
                model.swal({
                    title: 'Are you sure you want to proceed?',
                    // text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '',
                    confirmButtonText: 'Yes, Cancel this order!'
                }).then((result) => {
                   
                    if (result.isConfirmed) {
                        model.updateStatusAPI(6);
                    }
                })
            }
            else if(status_id == 5) // approved
            {
                model.updateStatusAPI(status_id);

            } else if(status_id == 8) // ready for collection
            {
                model.updateStatusAPI(status_id);

            } else if(status_id == 9) // collected
            {
                model.updateStatusAPI(status_id);
            } 
        },
        async downloadFolder() { //download for documents
            store.loading = true;
            let model=this;
            axios({
                url: process.env.VUE_APP_ENDPOINT+'/idtag/'+this.route.params.id+'/downloadFolder',
                method: 'GET',
                responseType: 'blob',
            }).then((res) => {
                store.loading = false;
                var FILE = window.URL.createObjectURL(new Blob([res.data]));
                var docUrl = document.createElement('a');
                docUrl.href = FILE;
                docUrl.setAttribute('download',model.route.params.id+'.zip');
                document.body.appendChild(docUrl);
                docUrl.click();
            });
        },
        async downloadFile(url) {
            return fetch(url)
                .then(response => response.blob()) 
                .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                // let ext=url.split('/').pop();
                a.download ='file.jpg';
                a.click();
                window.URL.revokeObjectURL(url);
            });
        },
        async downloadFiles(urls) { 
            store.loading = true;
            const promises = urls.map(url => this.downloadFile(url));
            Promise.all(promises)
            .then(() => {
                //console.log('All files downloaded');
                store.loading = false;
            })
            .catch(error => {
                console.error(error);
            });
        },
        async updateStatusAPI(status_id,remark="") {
            store.loading = true;
            let model = this;
            const fileType = [];
            const file = [];
            let syncProfilePhoto = false;

            if(status_id == 5) {
                if(this.photoToggleLabel && document.getElementById('coporate_photo_file').files.length > 0){
                    fileType.push('photo');
                    file.push(document.getElementById('coporate_photo_file').files[0]);
                    model.importMultipleFile(model.route.params.id, file, fileType, status_id);
                } else {
                    syncProfilePhoto = true;
                } 
            }
    
            await axios
            .put(process.env.VUE_APP_ENDPOINT+'/idtag/manage/orders/'+this.route.params.id,{
                order_status_id: status_id,
                remark:remark,
                sync_profile_photo: syncProfilePhoto
            })
            .then(function (res) {
              
                store.loading = false;
                model.swal({
                    icon: 'success',
                    text: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                });
                model.router.push({ name: 'IDTAGManage' });
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        showPreview() { 
            this.error.form = false;
            this.error.coporate_photo_file = '';
            
            var fileElement = document.getElementById('coporate_photo_file');
            this.info.photo = URL.createObjectURL(fileElement.files[0]);
        
            var preview = document.getElementById('profile_photo');
            preview.src = this.info.photo;
        },
        checkValidation(error_class){
            this.error.form = false;
            this.error[error_class]='';
        },
        checkRemarkValidation(error_class){
           
            if(this.incomplete_remark.length > 250){
                this.error.form = true;
                this.error[error_class]='Incomplete Information word must be lower than 250';
            }else{
                this.error.form = false;
                this.error[error_class]='';
            }
        },
        async importMultipleFile(order_id,files,file_types, status_id = ""){
            store.loading = true;
            let model =this;

            var formData = new FormData();
            formData.append("order_id", order_id);
            formData.append("status", 'update');
            formData.append("status_id", status_id);
            if(status_id == 5) {
                formData.append("file_name",this.order.order_by);
            }

            for (var i = 0; i < file_types.length; i++) {
                formData.append("files[]", files[i]);
                formData.append("file_types[]",file_types[i]);
            }

            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/idtag/multipleFileUpload',formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function () {
                store.loading = false;
                // let orderID   = model.orderID;
                // localStorage.removeItem('idtag');
                // model.router.push('/idtag/payment/'+orderID);
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status != 500) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        validate(){ 
            this.error.form = false;
            this.clear();

            if(store.idtag_detail.categoryID==1){

                if (this.photoToggleLabel) {  
                    if(!document.getElementById('coporate_photo_file').files.length && !store.idtag_docs.photo){
                        this.error.form = true;
                        this.error.coporate_photo_file = 'ERA Corporate Photo is required';
                    }
                }
                if (this.nricNameToggleLabel) {
                    if(!this.nric_name){
                        this.error.form = true;
                        this.error.nric_name = 'NRIC Name is required';
                    }
                    if(!document.getElementById('deep_poll_file').files.length && !store.idtag_docs.deep_poll){
                        this.error.form = true;
                        this.error.deep_poll_file = 'Deep poll file is required';
                    }
                    if(!document.getElementById('nric_file').files.length && !store.idtag_docs.nric){
                        this.error.form = true;
                        this.error.nric_file = 'NRIC (front view) is required';
                    }
                }
        
                if (this.businessNameToggleLabel && (!this.business_name)) {
                    this.error.form = true;
                    this.error.business_name = 'Business Name is required';
                }

                // if (this.remark) {
                //     this.error.form = true;
                //     this.error.business_name = 'Remark Info is no more than 500 words';
                // }

            }else{

                if (this.subCategoryId==4) {
                    if(!document.getElementById('police_file').files.length && !store.idtag_docs.police_file){
                        this.error.form = true;
                        this.error.police_file = 'Police report file is required';
                    }
                }
            }

        },
        async updateOrder () {  

            this.validate();

            if (!this.error.form) {

                store.loading = true;

                this.files=[];this.file_types=[]; var orderItem={};

                if(store.idtag_detail.categoryID==2){ // report_lost and wear tear  
                    if(this.subCategoryId==4 &&  document.getElementById('police_file').files.length > 0){
                        this.file_types.push('police_file');
                        this.files.push(document.getElementById('police_file').files[0]);
                    }
                    orderItem={
                        category_id      :store.idtag_detail.categoryID,
                        sub_category_ids :[this.subCategoryId]
                    };
                }
                else //change particuar order//cateogryID==1
                { 
                    if(this.photoToggleLabel && document.getElementById('coporate_photo_file').files.length > 0){
                        this.file_types.push('photo');
                        this.files.push(document.getElementById('coporate_photo_file').files[0]);
                    }
                    if(this.nricNameToggleLabel){
                        if(document.getElementById('deep_poll_file').files.length > 0){
                            this.file_types.push('deep_poll');
                            this.files.push(document.getElementById('deep_poll_file').files[0]);
                        }
                        if(document.getElementById('nric_file').files.length > 0){
                            this.file_types.push('nric');
                            this.files.push(document.getElementById('nric_file').files[0])
                        }
                    }
                    orderItem={
                        category_id   : store.idtag_detail.categoryID,
                        sub_category_ids:this.subCategoryIds, 
                        nric_name     : this.nric_name,
                        business_name : this.business_name
                    };
                }

                let model=this;
               
                await axios
                .put(process.env.VUE_APP_ENDPOINT+'/idtag/manage/orders/update/'+this.route.params.id,orderItem) 
                .then(function (res){
            
                    if(model.file_types.length) model.importMultipleFile(model.route.params.id,model.files,model.file_types);

                    localStorage.removeItem('idtag');

                    store.loading = false;

                    model.swal({
                        icon: 'success',
                        text: res.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                    
                    model.router.push({ name: 'IDTAGManage' });
                })
                .catch(function (error) {
                    store.loading = false;
                    if (error.response && error.response.status == 401) {
                        model.router.push({ name: 'Logout' });
                    }else if (error.response && error.response.status == 405) {
                        model.router.push({ name: 'Logout' });
                    }
                    else if (error.response && error.response.status == 422) {
                        model.swal({
                            icon: 'error',
                            text: error.response.data.message,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                    else {
                        model.swal({
                            icon: 'error',
                            text: model.app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            }
        },
        nameToggle() {
            let model = this;
            model.nameToggleLabel = !model.nameToggleLabel;
            if(model.nameToggleLabel)
            {
                store.namecard.user.name = model.namewithInitial;
            } else {
                store.namecard.user.name = model.namenoInitial;
            }
        },
        nricNameToggle() {  
            let model = this;
            model.nricNameToggleLabel = !model.nricNameToggleLabel;
            if(!model.nricNameToggleLabel){
                model.nric_name =store.idtag.user.nric_name;
            }
        },
        businessNameToggle() {  
            let model = this;
            model.businessNameToggleLabel=!model.businessNameToggleLabel;
            if(!model.businessNameToggleLabel)
            {
                model.business_name =store.idtag.user.business_name;
            }
        },
        photoToggle() {
            let model = this;
            model.photoToggleLabel = !model.photoToggleLabel;
            if(model.photoToggleLabel)
            {
                if(this.info.photo) //uploaded profile
                    document.getElementById('profile_photo').src=this.info.photo;
                else if(store.idtag_docs.photo) //ordered profile
                    document.getElementById('profile_photo').src=store.idtag_docs.photo;
                else //old profile
                    document.getElementById('profile_photo').src= store.idtag.user.coporate_photo;  
            } else {
                document.getElementById('profile_photo').src= store.idtag.user.coporate_photo; //old profile 
            }
        },
        reportLostWTToggle(){
            let model = this;
            model.reportLostWTLabel = !model.reportLostWTLabel;
            
        },
        clear(cl_err) {
            if(!cl_err)
                this.error = [];
            else{
                this.error.form = true;
                this.error={cl_err :''}
            }
        },
        exportPreview() {
            let model=this;
            const previewPage = document.getElementById('preview-page');
            html2canvas(previewPage,{
                useCORS: true,
                allowTaint: true,
                logging: true
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg');
                const img = new Image();
                img.src = imgData;
                const link = document.createElement('a');
                link.download = model.assc_code+'.jpg'; 
                link.href = imgData;
                link.click();
            });
        },
    },
    watch: {
       
    }
}
</script>
<style>
    .photo_remark{
       color:#d63939;
       font-size: 13px; 
    }

    .required:before {
        content:" *";
        color: #d63939;
        font-size: 16px;
    }
    .card-footer .text-center button {
        margin-right: 2px;
    }

    .row .text-left button {
        margin-right: 2px;
        margin-top:2px;
        float:left;
    }
</style>
