import { createRouter, createWebHistory } from 'vue-router';
import Auth from '../views/Account/AuthPage.vue';
import Login from '../views/Account/LoginPage.vue';
import Logout from '../views/Account/LogoutPage.vue';
import Namecard from '../views/Namecard/OrderPage.vue';
import NamecardAdd from '../views/Namecard/AddPage.vue';
import CheckOutPage from '../views/Namecard/CheckOutPage.vue';
import Home from '../views/HomePage.vue';
import PaymentPage from '../views/Namecard/PaymentPage.vue';
import NamecardManage from '../views/Namecard/NamecardManage.vue';
import NamecardManageDetails from '../views/Namecard/NamecardManageDetails.vue';
import OrderDetails from '../views/Namecard/OrderDetails.vue';
import AddAssocOrder from '../views/Namecard/AddAssocOrder.vue';
import AddStaffOrder from '../views/Namecard/AddStaffOrder.vue';
//IDTAG
import IDTAG from '../views/IDTAG/OrderPage.vue';
import IDTAGOrderDetails from '../views/IDTAG/OrderDetails.vue';
import IDTAGPaymentPage from '../views/IDTAG/PaymentPage.vue';
import IDTAGParticularOrder from '../views/IDTAG/ParticularOrder.vue';
import IDTAGLostOrder from '../views/IDTAG/LostOrder.vue';
import IDTAGManage from '../views/IDTAG/ManageOrder.vue';
import IDTAGManageDetails from '../views/IDTAG/ManageDetails.vue';

import IDTAGMassExport from '../views/IDTAG/MassExport.vue';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/eventweb/ticket/:ticketNo',
            name: 'EventWebTicket',
            component: () => import('@/views/EventWeb/TicketPage.vue'),
            meta: {
                title: 'Event Website'
            }
        },
        {
            path: '/eventweb/greenpledge',
            name: 'EventWebGreenPledge',
            component: () => import('@/views/EventWeb/Greenpledge/IndexPage.vue'),
            meta: {
                title: 'Event Website'
            }
        },
        {
            path: '/namecard/manage/add-staff',
            name: 'AddStaffOrder',
            component: AddStaffOrder,
            meta: {
                title: 'AddStaffOrder'
            }
        },
        {
            path: '/namecard/manage/add',
            name: 'AddAssocOrder',
            component: AddAssocOrder,
            meta: {
                title: 'AddAssocOrder'
            }
        },
        {
            path: '/namecard/:id',
            name: 'OrderDetails',
            component: OrderDetails,
            meta: {
                title: 'OrderDetails'
            }
        },
        {
            path: '/namecard/manage/:id',
            name: 'NamecardManageDetails',
            component: NamecardManageDetails,
            meta: {
                title: 'NamecardManageDetails'
            }
        },
        {
            path: '/namecard/manage',
            name: 'NamecardManage',
            component: NamecardManage,
            meta: {
                title: 'NamecardManage'
            }
        },
        {
            path: '/namecard/payment/:id',
            name: 'PaymentPage',
            component: PaymentPage,
            meta: {
                title: 'Namecard Payment'
            }
        },
        {
            path: '/namecard/add',
            name: 'NamecardAdd',
            component: NamecardAdd,
            meta: {
                title: 'NamecardAdd'
            }
        },
        {
            path: '/namecard',
            name: 'Namecard',
            component: Namecard,
            meta: {
                title: 'Namecard'
            }
        },
        {
            path: '/checkout',
            name: 'CheckOutPage',
            component: CheckOutPage,
            meta: {
                title: 'Order Summary'
            }
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
            meta: {
                title: 'Logout'
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                title: 'Login'
            }
        },
        {
            path: '/auth',
            name: 'Auth',
            component: Auth,
            meta: {
                title: 'Auth'
            }
        },
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        //IDTAG START
        {
            path: '/idtag',
            name: 'IDTAG',
            component: IDTAG,
            meta: {
                title: 'IDTAG'
            }
        },
        {
            path: '/idtag/:id',
            name: 'IDTAGOrderDetails',
            component: IDTAGOrderDetails,
            meta: {
                title: 'IDTAGOrderDetails'
            }
        },
        {
            path: '/idtag/payment/:id',
            name: 'IDTAGPaymentPage',
            component: IDTAGPaymentPage,
            meta: {
                title: 'IDTAG Payment'
            }
        },
        {
            path: '/idtag/order/particular',
            name: 'IDTAGParticularOrder',
            component: IDTAGParticularOrder,
            meta: {
                title: 'IDTAG Particular Order'
            }
        },
        {
            path: '/idtag/order/lost',
            name: 'IDTAGLostOrder',
            component: IDTAGLostOrder, 
            meta: {
                title: 'IDTAG Lost Order'
            }
        },
        {
            path: '/idtag/manage',
            name: 'IDTAGManage',
            component: IDTAGManage,
            meta: {
                title: 'IDTAGManage'
            }
        },
        {
            path: '/idtag/manage/:id',
            name: 'IDTAGManageDetails',
            component: IDTAGManageDetails,
            meta: {
                title: 'IDTAGManageDetails'
            }
        },
        {
            path: '/idtag/manage/mass-export',
            name: 'IDTAGMassExport',
            component: IDTAGMassExport,
            meta: {
                title: 'IDTAGMassExport'
            }
        },
        //IDTAG END
    ]
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title+' - myERA';
    } else {
        document.title = 'myERA';
    }
	next();
});

export default router