<template>
    <div class="namecard agent ta1">
        <div class="preview" :style="'background-image: url('+ namecard.template.front_bg +')'">
            <div class="photo">
                <img :src="namecard.user.photo" id="profilePhoto"/>
            </div>
            <div class="box-info">
                <h1 class="name">{{ namecard.user.name }} {{ namecard.user.namecn }}</h1>
                <h3 class="qualification">{{ namecard.user.position }} {{ namecard.user.ranknamecn }}</h3>
                <h3 class="phone font-normal">+65 {{ namecard.user.phone }}</h3>
                <h3 class="email font-normal">{{ namecard.user.email }}</h3>
            </div>
            <div class="box-cert">
                <h3 class="cert font-normal">
                    <span v-if="namecard.user.certInCes" style="white-space: break-spaces;">&nbsp;{{ namecard.user.certInCes }}</span>
                    <span v-else>&nbsp;</span>
                    <div v-if="namecard.user.certInCes2">&nbsp;{{ namecard.user.certInCes2 }}</div>
                </h3>
                <h3 class="ceaNo font-normal">CEA Reg. No.: {{ namecard.user.cea_regno }}</h3>
            </div>
        </div>
        <div class="preview-back" :style="'background-image: url('+ namecard.template.back_bg +');'">
            <ul class="social" v-if="namecard.social">
                <li class="web"><span>{{ namecard.social.web }}</span></li>
                <li class="fb"><span>{{ namecard.social.fb }}</span></li>
                <li class="ig"><span>{{ namecard.social.ig }}</span></li>
                <li class="tiktok"><span>{{ namecard.social.tiktok }}</span></li>
            </ul>
            <div class="qr">
                <div class="qr-box"><qrcode-vue :value="qrcodeValue" :size="qrcodeSize" level="H" id="backQRCode"/></div>
                <h3>www.era.com.sg/{{ namecard.user.cea_regno }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '../../../store.js';
import { profilePhoto, realtyWatchQRCode } from '@/helpers/namecard/photo';
import { ref, onMounted } from 'vue';
import QrcodeVue from 'qrcode.vue';

export default {
    components: {
      QrcodeVue
    },
    setup() {
        const namecard = ref(store.namecard);
        const qrcodeSize = ref(130);
        const qrcodeValue = ref('https://www.era.com.sg/'+store.namecard.user.cea_regno);

        onMounted(() => {
            const photo = document.getElementById('profilePhoto')
            const qrcode = document.getElementById('backQRCode')
            profilePhoto(photo);
            realtyWatchQRCode(qrcode);
        });

        return { store, namecard, qrcodeSize, qrcodeValue };
    }
}
</script>

<style>
.ta1{
    font-family: 'Gotham' !important;
}
.name, .qualification{
    font-family: 'Gotham Bold' !important;
}
.namecard.agent .social li {
    margin: 0 !important;
}
</style>