import { reactive } from 'vue';

export const store = reactive({
    loading: false,
    pay: {
        id: '',
        service: '',
        name: '',
        description: '',
        method: '',
        amount: '',
        points: '',
        points_text: '',
        redirect: '' //redirect after payment
    },
    namecard: {
        user: false,
        template: false,
        package: false,
        amount: false,
        social: false
    },
    idtag: {  
        user:false,
        subcategory:false,
        files:[]
    },
    idtag_docs:{    // for file download 
        'nric':'',
        'deep_poll':'',
        'police_file':'',
        'photo':''
    },
    idtag_pay:{    //payment checkout
        id: '',
        service: '',
        name: '',
        description: '',
        method: '',
        amount: '',
        points: '',
        total_points:'',
        total_points_text: '',
        redirect: '' 
    },
    idtag_detail:{
        'categoryID':''  //orderDetail for edit preview 
    }
   
})