<template>
  <div class="page">
      <!-- Navbar -->
      <header class="navbar navbar-expand-md navbar-dark d-print-none">
        <div class="container-xl">
          <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <a href="#">
              <img src="https://mytest.era.com.sg/images/logo_white.png" width="110" height="32" alt="Tabler" class="navbar-brand-image">
              <span>myERA</span>
            </a>
          </h1>
          
          <div class="navbar-nav flex-row order-md-last">
            <div class="nav-item dropdown" v-if="store.user">
              <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                <span class="avatar avatar-sm" :style="'background-image: url(https://developer.era.com.sg/agent/profile_photo/'+store.user.username+')'"></span>
                <div class="d-none d-xl-block ps-2">
                  <div>{{ store.user.name }}</div>
                  <div class="mt-1 small" v-if="store.user.role == 'ASSOC'">Associate</div>
                  <div class="mt-1 small" v-else>Staff</div>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <!--<a href="#" class="dropdown-item">Status</a>
                <a href="#" class="dropdown-item">Profile</a>
                <a href="#" class="dropdown-item">Feedback</a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">Settings</a>-->
                <router-link :to="{ name: 'Logout' }" class="dropdown-item">Logout</router-link>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!-- nav here -->
      <div class="page-wrapper">
          <router-view></router-view>

        <footer class="footer footer-transparent d-print-none">
          <div class="container-xl">
            <div class="row text-center align-items-center flex-row-reverse">
              <div class="col-lg-auto ms-lg-auto">
                <ul class="list-inline list-inline-dots mb-0">
                  <li class="list-inline-item">
                    <a href="#" class="btn btn-link text-decoration-none">
                      <i class="ti ti-mail"></i> <span>era@era.com.sg</span>
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#" class="btn btn-link text-decoration-none">
                      <i class="ti ti-device-mobile"></i> <span>+65 6226 2000</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-lg-auto mt-3 mt-lg-0">
                <ul class="list-inline list-inline-dots mb-0">
                  <li class="list-inline-item">
                    Copyright &copy; 2022
                    <a href="#" class="link-secondary">ERA Realty Network Private Limited</a>.
                     All Rights Reserved.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>

    </div>

    <div id="modal-loader" class="modal-loader" v-if="store.loading">
      <div class="spinner">
        <div class="spinner-border text-danger" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
</template>

<script>
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import { store } from './store.js';

export default({
  setup() {
    const app = getCurrentInstance();
    const swal = inject('$swal');
    const router = useRouter();
    const route = useRoute();    
    const authToken = localStorage.getItem('authToken');
    const showMenu = ref(false);
    const sidebartype = ref('mini-sidebar');
    const user = ref([]);
    const badges = ref([]);
    const token = ref();
    const menus = ref([
      {
        name: 'Login',
        icon: 'ti ti-login',
        link: '/login'
      },
    ]);

    async function init() {
      axios.interceptors.request.use(function (config) {
        config.headers.Authorization =  'Bearer ' + localStorage.getItem('authToken');
        return config;
      }, function (error) {
        return Promise.reject(error);
      });

      axios.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        return Promise.reject(error);
      });

      if (!authToken) {
        return false;
      }
      await axios
        .get(process.env.VUE_APP_ENDPOINT+'/auth/info',{
          type: 'info'
        })
        .then(function (res) {
          store.user = res.data.user;
          //menus.value = res.data.menus;
        })
        .catch(function (error) {
            if (error.response.status == 401) {
               router.push({ name: 'Logout' });
            }
        });

    }
    
    onMounted(() => {
      var path = window.location.pathname;
      var page = path.split("/").pop();
      if (page != 'auth') {
        init();
      }
    });

    return { app, route, router, authToken, showMenu, sidebartype, user, badges, menus, swal, token, store };
  },
  methods: {
    miniSidebar() {
      this.sidebartype = 'mini-sidebar';
      this.showMenu = false;
    },
    toggleMenu() {
      this.showMenu = this.showMenu ? false: true;
      if (this.showMenu) {
        this.sidebartype = 'show-sidebar';
      } else {
        this.sidebartype = 'mini-sidebar';
      }
    }
  }

})
</script>