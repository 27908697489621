<template>
    <!-- <div class="idtag agent ta1"> -->
    <div>
        <div class="card" id="preview-page" style="width:406px;height:678px;margin:auto"> 
            <div class="card-body row">
                <div class="col-4" style="padding:0px">
                    <img src="https://d2u1upwdbw709w.cloudfront.net/documents/EXTERNAL+DOCUMENTS/MYERA/myera_logo_red.jpeg"/>
                </div>

                <div class="col-8" style="margin-top:20px">
            
                    <h1 class="name" style="font-weight: bold;line-height: 28px;font-size: 23px; margin-bottom: 5px;letter-spacing:-0.015em;">
                        ERA REALTY <br/> NETWORK PTE LTD
                    </h1>
                    <span style="font-size: 16px;margin-bottom: 7px; font-weight: 600;line-height: 16px;font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;">
                       Estate Agent License <br/> No. L3002382K
                    </span> 
                </div>

                <div class="profilePhoto mb-3 col-12  text-center" style="margin-top: -10px;margin-top: -10px;height:320px;overflow: hidden;">
                     <img :src="coporate_photo" id="profile_photo" style="width:250px;height:auto" /> 
                </div>

                <div class="name col-12 text-center">
                    <h3 style="font-family:myriad-pro-bold, sans-serif;font-size: 23px; font-weight: bold;padding-left: 20px;padding-right: 20px;margin: 0;line-height: 28px;">
                        {{ removeSpecialCharNricName(nric_name) }} <br/>
                        {{removeInitialBusinessName(business_name)}}
                    </h3>
                </div>

                <div class="row align-items-center text-center">
                    <h3>
                        <span style="display: inline-block; margin-right: 5px; font-size:18px">CEA Registration No:</span>
                        <strong class="text-font" style="font-size:23px;">{{ cea_regno }}</strong>
                   </h3>
                </div>

            </div>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>

import { removeSpecialCharNricName,removeInitialBusinessName } from '@/helpers/idtag/filterName';

export default {

    props:['business_name','nric_name','coporate_photo','cea_regno'],

    setup(){
        return { removeSpecialCharNricName,removeInitialBusinessName };
    },
    methods:{
    }
}
</script>


<style>

.card-header,.card-footer,.card-body{
    border-color: 0 !important;
    border-bottom: 0 !important;
    border-top:0 !important;
}
.card-footer{
    background-color: #FFF!important;
}

.ta1{
    font-family: 'Gotham' !important;
}
.name, .qualification{
    font-family: 'Gotham Bold' !important;
}
</style>