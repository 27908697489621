<template>
    <div>
        <div v-if="orderStatus == 'PENDING'">
            <span class="status status-dark"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'APPROVED'">
            <span class="status status-yellow"> {{orderStatus}}</span>
        </div>
        <div v-if="orderStatus == 'PAID'">
            <span class="status status-green"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'PROCESSING'">
            <span class="status status-blue"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'READY FOR COLLECTION'">
            <span class="status status-orange">{{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'COLLECTED'">
            <span class="status status-cyan"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'CANCELLED'">
            <span class="status status-red"> {{orderStatus}}</span>
        </div>
        <div v-else-if="orderStatus == 'INCOMPLETE'">
            <span class="status status-red"> {{orderStatus}}</span>
        </div>
    </div>
</template>

<script>

export default {
	props: ['orderStatus']
}
</script>