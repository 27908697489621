<template>
    
    <PageTitle title="Add Staff Namecard" :breadcrumb="[ { name: 'Manage Namecard', link: '/namecard/manage' }, { name: 'Add Staff Namecard' } ]"></PageTitle>
    <div class="page-body">
        <div class="container-xl">
            <div class="row row-cards">
                <div class="col-12">
                    <div class="card mb-3">
                        <div class="card-body mb-4">
                            <h4 class="card-title">Search Staff</h4>
                            <div class="mb-3 row">
                                <div class="col-md-4 mb-3">
                                    <SearchStaff/>
                                </div>
                                <div class="col-md-4">
                                    <button type="submit" class="btn btn-red ms-auto" style="margin-top: 3px;" @click="searchUser()">
                                        <i class="ti ti-search"></i>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="card mb-3" v-if="showDetails">
                        <div class="card-body mb-4">
                            <h4 class="card-title">Select Template</h4>
                            <div class="card-subtitle">Click the image to select template</div>
                            <div class="row namecard-template text-center">
                                <div class="col-lg-2" v-for="(template, index) in templates" :key="index">
                                    <img ondragstart="return false;" :src="template.front_template" @click="setTemplate(template)" :data-id="template.id" 
                                    :class="[ template.id == form.template_id ? 'active': '' ]"/>
                                    <h2 class="text-muted">{{ template.name }}</h2>
                                </div>
                            </div>
                        </div>
                        <div class="card-body mb-4" v-if="form.template_id">
                            <h4 class="card-title">User Info</h4>
                            <div class="row">
                                <div class="col-lg-5 mb-3">

                                    <div class="mb-3">
                                        <div class="form-label">Name</div>
                                        <input type="text" class="form-control" v-model="name" :class="{ 'is-invalid': error.name}">
                                        <div class="invalid-feedback">{{ error.name }}</div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-label">Name (Chinese)</div>
                                        <input type="text" class="form-control" v-model="namecn">
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-label">Designation</div>
                                        <input type="text" class="form-control" v-model="designation" :class="{ 'is-invalid': error.designation}">
                                        <div class="invalid-feedback">{{ error.designation }}</div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-label">Designation (Chinese)</div>
                                        <input type="text" class="form-control" v-model="designationcn">
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-label">Phone</div>
                                        <input type="text" class="form-control" v-model="phone" :class="{ 'is-invalid': error.phone}">
                                        <div class="invalid-feedback">{{ error.phone }}</div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label">Email</label>
                                        <input type="email" class="form-control" v-model="email" :class="{ 'is-invalid': error.email}">
                                        <div class="invalid-feedback">{{ error.email }}</div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label">Order Quantity</label>
                                        <VueMultiselect selectLabel="" v-model="package_id" :options="packages" :multiple="false" group-values="packages" group-label="category" :group-select="false" placeholder="Type to search" track-by="name" label="name" :class="{ 'is-invalid': error.package_id}">
                                            <span>Oops! No elements found. Consider changing the search query.</span>
                                        </VueMultiselect>
                                        <div class="invalid-feedback">{{ error.package_id }}</div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label">Payment Reference No.</label>
                                        <input type="text" class="form-control" v-model="form.payment_refno" :class="{ 'is-invalid': error.payment_refno}">
                                        <div class="invalid-feedback">{{ error.payment_refno }}</div>
                                    </div>

                                    <div class="mt-4">
                                        <div class="d-flex">
                                            <router-link :to="{ name: 'Namecard' }" class="btn btn-link">Cancel</router-link>
                                            <button type="submit" class="btn btn-success ms-auto" @click="submit" v-if="package_id">
                                                <span>Place Order</span> 
                                                <i class="ti ti-chevron-right"></i>
                                            </button>
                                            <button type="submit" class="btn btn-success ms-auto" disabled="disabled" v-else>
                                                <span>Place Order</span> 
                                                <i class="ti ti-chevron-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7 overflow-auto">
                                    <CustomTs5 v-if="form.template.name == 'S1'"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import PageTitle from '../../components/PageTitle.vue';
import SearchStaff from '../../components/SearchStaff.vue';
import CustomTs5 from './components/CustomTs5.vue';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import { store } from '../../store.js';
import axios from 'axios';
import VueMultiselect from 'vue-multiselect';

export default {
    components: {
        PageTitle,
        CustomTs5,
        VueMultiselect,
        SearchStaff
    },
    setup() {
        const app = getCurrentInstance();
        const router = useRouter();
        const swal = inject('$swal');
        const form = ref([]);
        const social = ref([]);
        const templates = ref([]);
        const package_id = ref(false);
        const info = ref([]);
        const showDetails = ref(false);
        const name = ref('');
        const namecn = ref('');
        const email = ref('');
        const phone = ref('');
        const designation = ref('');
        const designationcn = ref('');
        const error = ref([]);
        const packages = ref([]);
        const asc_code = ref('');
        const associate = ref([]);
        const associates = ref([]);
        const loading = ref(false);

        async function getTemplates() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/namecard/templates',{
                params: {
                    user_type: 'STAFF'
                }
            })
            .then(function (res) {
                store.loading = false;
                templates.value = res.data.namecards;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        async function getPackages() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/namecard/packages')
            .then(function (res) {
                store.loading = false;
                packages.value = res.data.packages;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        onMounted(() => {
            getTemplates();
            getPackages();
        });

        return { app, store, swal, router, form, templates, package_id, info, social, error, packages,
            asc_code, associate, associates, showDetails, loading,
            name, namecn, email, phone, designation, designationcn
        }
    },
    methods: {
        async searchUser() {
            this.clear();
            this.error.form = false;
            this.showDetails = false;
            this.info = [];
            this.email = '';
            this.form.template_id = '';

            if (!this.associate) {
                return false;
            }

            if (this.error.form == false) {
                store.loading = true;
                let model = this;
                await axios
                .get(process.env.VUE_APP_ENDPOINT+'/user/details/'+store.staff.code)
                .then(function (res) {
                    store.loading = false;
                    model.info.name = res.data.user.user_name;
                    model.info.phone = res.data.user.user_handphone;
                    model.info.email = res.data.user.user_email;
                    model.name = res.data.user.user_name;
                    model.email = res.data.user.user_email;
                    model.phone = res.data.user.user_handphone;
                    model.showDetails = true;
                })
                .catch(function (error) {
                    store.loading = false;
                    if (error.response && error.response.status == 401) {
                        model.router.push({ name: 'Logout' });
                    } else if (error.response && error.response.status == 422) {
                        model.swal({
                            icon: 'error',
                            text: error.response.data.message,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    } else {
                        model.swal({
                            icon: 'error',
                            text: model.app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            }

        },
        setTemplate (template) {
            this.form.template_id = template.id;
            this.form.template = template;
            store.namecard.template = template;
            store.namecard.user = this.info;
            store.namecard.social = this.social;
        },
        uploadPhoto() {
            var fileElement = document.getElementById('inputFile');
            this.info.photo = URL.createObjectURL(fileElement.files[0]);
        },
        submit () {
            this.error.form = false;
            this.clear();

            if (!this.email) {
                this.error.form = true;
                this.error.email = 'Email is required';
            }

            if (!this.name) {
                this.error.form = true;
                this.error.name = 'Name is required';
            }

            if (!this.designation) {
                this.error.form = true;
                this.error.designation = 'Designation is required';
            }

            if (!this.phone) {
                this.error.form = true;
                this.error.phone = 'Phone is required';
            }

            if (!this.email) {
                this.error.form = true;
                this.error.email = 'Email is required';
            }

            if (!this.package_id) {
                this.error.form = true;
                this.error.package_id = 'Package is required';
            }

            if (!this.form.payment_refno) {
                this.error.form = true;
                this.error.payment_refno = 'Payment Reference No is required';
            }

            if (!this.error.form) {
                this.goSubmit();
            }
        },
        async goSubmit() {
            store.loading = true;
            let model = this;
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/namecard/manage',{
                user_type: 'STAFF',
                user_id: store.staff.code,
                namecard_id: this.form.template_id,
                package_id: this.package_id.id,
                email: this.email,
                name: this.name,
                name_cn: this.namecn,
                rank: this.info.position,
                rank_cn: this.info.position_cn,
                phone: this.info.phone,
                payment_refno: this.form.payment_refno,
            })
            .then(function (res) {
                store.loading = false;
                localStorage.removeItem('namecard');

                model.swal({
                    icon: 'success',
                    html: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                });
                model.router.push({ name: 'NamecardManage' });
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        clear() {
            this.error = [];
        }
    },
    
    watch: {
        email () {
            if (!this.email) {
                this.error.form = true;
                this.error.email = 'Email required';
            } else {
                if (store.namecard.user) {
                    store.namecard.user.email = this.email;
                }
                this.error.form = false;
                this.error.email = '';
            }
        },
        name() {
            if(store.namecard.user) {
                store.namecard.user.name = this.name;
            }
        },
        namecn() {
            if(store.namecard.user) {
                store.namecard.user.namecn = this.namecn;
            }
        },
        designation() {
            if(store.namecard.user) {
                store.namecard.user.position = this.designation;
            }
        },
        designationcn() {
            if(store.namecard.user) {
                store.namecard.user.position_cn = this.designationcn;
            }
        },
        phone() {
            if(store.namecard.user) {
                store.namecard.user.phone = this.phone;
            }
        },
    }
}
</script>
<style src="@/assets/vue-multiselect.min.css"></style>
<style>
    .namecard-template img {
        width: 250px;
        border: 3px solid #EAECEE;
        cursor: pointer;
    }

    .namecard-template img:hover {
        border: 3px solid #981D10
    }

    .namecard-template img.active {
        border: 3px solid #981D10
    }

    .multiselect__option--group {
        font-weight: bold;
    }

    .multiselect__option--highlight {
        background:#FCEBEC;
        color:#D63F3E;
    }
</style>