<template>
    <PageTitle title="Namecard Payment" :breadcrumb="[ { name: 'Namecard', link: '/namecard' }, { name: 'Payment' } ]"></PageTitle>

    <div class="page-body">
        <div class="container-xl">
            <div class="row row-cards">
                <div class="col-12">
                    <PaymentForm v-if="store.showPaymentForm"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentForm from '../../components/PaymentForm.vue';
import PageTitle from '../../components/PageTitle.vue';
import { store } from '../../store.js';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';

export default {
    components: {
        PageTitle,
        PaymentForm
    },
    setup() {
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const namecard = ref(store.namecard);
        const user = ref([]);
        store.showPaymentForm = false;

        async function getInfo() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/namecard/user')
            .then(function (res) {
                store.loading = false;
                user.value = res.data.user;
                store.pay.points = res.data.user.total_points;
                store.pay.points_text = res.data.user.total_points_text;
                store.pay.name = res.data.user.name + ' namecard';
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        async function getOrder() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/namecard/orders/'+route.params.id)
            .then(function (res) {
                store.loading = false;
                store.pay.id = res.data.order.id;
                store.pay.description = res.data.order.package_name;
                store.pay.amount = res.data.order.package_amount;
                store.pay.amount_text = res.data.order.package_amount_text;
                store.pay.due = res.data.order.order_expiration;
                store.pay.payment_refno = res.data.order.payment_refno;
                store.pay.status = res.data.order.status;
                store.pay.service = 'namecard';
                store.pay.redirect = 'Namecard';
                store.showPaymentForm = true;

                if (res.data.order.status == 'PAID') {
                    router.push({ name: 'OrderDetails', params: { id:route.params.id } });
                }
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        onMounted(() => {
            getInfo();
            getOrder();
        });

        return { store, swal, router, namecard, user };
    }
}
</script>