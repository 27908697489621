<template>
    <div class="card mb-3">
        <div class="card-body mb-4">
            <h2 class="mb-3 text-muted">Order Summary</h2>
            <div class="table-responsive">
                <table class="table table-vcenter">
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ store.idtag_pay.name }}</td> 
                            <td>{{ store.idtag_pay.description}}</td>
                            <td v-if="method=='POINTS'">ERA points ({{ store.idtag_pay.points }})</td>
                            <td v-else>{{ store.idtag_pay.amount_text }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mb-3 mt-5">
                <div class="mb-3 col-md-12">
                    <label class="form-label">Payment method</label>
                    <div class="form-selectgroup form-selectgroup-boxes">
                        <label class="form-selectgroup-item flex-fill">
                            <input type="radio" v-model="method" value="PAYNOW" class="form-selectgroup-input">
                            <div class="form-selectgroup-label d-flex align-items-center p-3">
                            <div class="me-3">
                                <span class="form-selectgroup-check"></span>
                            </div>
                            <div>
                                <div class="pay paynow"></div>
                            </div>
                            </div>
                        </label>

                        <label class="form-selectgroup-item flex-fill" v-if="store.idtag_pay.total_points >= store.idtag_pay.points">
                            <input type="radio" v-model="method" value="POINTS" class="form-selectgroup-input">
                            <div class="form-selectgroup-label d-flex align-items-center p-3">
                                <div class="me-3">
                                    <span class="form-selectgroup-check"></span>
                                </div>
                                <div>
                                    <!-- <div class="font-weight-medium">Use ERA points</div> -->
                                    <div class="font-weight-medium">Use ERA points    ( {{ store.idtag_pay.points }} )</div>
                                    <div class="text-muted">Balance: You have {{ store.idtag_pay.total_points_text }} ERA points</div>
                                </div>
                            </div>
                        </label>

                        <label class="form-selectgroup-item flex-fill" v-else>
                            <input type="radio" v-model="method" value="POINTS" class="form-selectgroup-input" disabled="disabled">
                            <div class="form-selectgroup-label d-flex align-items-center p-3">
                                <div class="me-3">
                                    <span class="form-selectgroup-check"></span>
                                </div>
                                <div>
                                    <div class="font-weight-medium">Use ERA points ( {{ store.idtag_pay.points }} ) - Not enough points</div>
                                    <div class="text-muted">Balance: You have {{ store.idtag_pay.points_text }} ERA points</div>
                                </div>
                            </div>
                        </label>
                        
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-12">
                    <PaymentPaynow v-if="store.idtag_pay.method == 'PAYNOW' && store.idtag_pay.status != 'COFM'"/>
                </div>
            </div>

            <button type="submit" class="btn btn-success btn-lg ms-auto" @click="paypoints" v-if="store.idtag_pay.method == 'POINTS'">
                <span>Confirm & Pay</span> 
            </button>
            
        </div>

    </div>
</template>

<script>
import PaymentPaynow from './IDTagPaymentPaynow';
import { store } from '../store.js';
import { ref, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';

import { onMounted } from 'vue';

export default {
    components: {
        PaymentPaynow
    },
    setup() {
        const method = ref(store.idtag_pay.method);
        const app = getCurrentInstance();
        const router = useRouter();
        const swal = inject('$swal');
        const route = useRoute();

        onMounted(() => {
           
        });

        return  { app, router, route, swal, store, method};
    },
    watch: {
        async method() {
            store.loading = true;
            let model = this;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/idtag/orders/'+this.route.params.id+'/checkout')// just checking payment status
            .then(function (res) {
                store.loading = false; 
                store.idtag_pay.ref_id      = res.data.data.id; 
                store.idtag_pay.description = res.data.data.sub_category_str;
                store.idtag_pay.amount      = res.data.data.price,
                store.idtag_pay.amount_text = res.data.data.amount_text;
                store.idtag_pay.points      = res.data.data.era_points;

                store.idtag_pay.due           = res.data.data.payment_expiry_date;
                store.idtag_pay.payment_refno = res.data.data.payment_id;
                store.idtag_pay.status        = res.data.data.payment_status;
                store.idtag_pay.service       = 'IDTAG'; 
                store.idtag_pay.redirect      = 'IDTAG';
                store.showPaymentForm = true;

                if (res.data.data.payment_status == 'COFM') {
                    model.router.push({ name: 'IDTAGOrderDetails', params: { id: model.route.params.id } });
                } else {
                    store.idtag_pay.method = model.method;
                }
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    },
    methods: {
        async paypoints() {
            let model = this;
            store.loading = true;
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/idtag/pay/points',{ 
                refNo: store.idtag_pay.ref_id,
                service: store.idtag_pay.service
            })
            .then(function (res) {
                store.loading = false;
                model.swal({
                    icon: 'success',
                    html: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                });
                model.router.push({ name: store.idtag_pay.redirect });
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
    }
}
</script>

<style scoped>
    .pay.paynow {
        /* width: 50px; height: 40px; display:block; */
        width: 70px; height: 45px;
        display:block;
        background-image: url('https://d2u1upwdbw709w.cloudfront.net/documents/EXTERNAL+DOCUMENTS/MYERA/paynow-btn.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position:  center, 50%, 50%;
    }
    .ins {
        margin-left: 15px;
    }
</style>