<template>
    <PageTitle title="Namecard Details" :breadcrumb="[ { name: 'Namecard', link: '/namecard' }, { name: 'Namecard Details' } ]">
    </PageTitle>

    <div class="page-body">
        <div class="container-xl">
            <div class="row row-cards">
                <div class="col-lg-8">
                    <div class="card mb-3">
                        <div class="card-header">
                            <div class="card-title">Details</div>
                        </div>
                        <div class="card-body">
                            <div class="datagrid">
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Submitted By</div>
                                    <div class="datagrid-content">
                                    <div class="d-flex align-items-center">
                                        <span class="avatar avatar-xs me-2 avatar-rounded" :style="{ backgroundImage: 'url(https://developer.era.com.sg/agent/profile_photo/'+order.order_by+')' }"></span>
                                        {{ order.user_name }}
                                    </div>
                                    </div>
                                </div>
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Associate Code</div>
                                    <div class="datagrid-content">{{ order.order_by }}</div>
                                </div>
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Email</div>
                                    <div class="datagrid-content">{{ order.email }}</div>
                                </div>
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Phone</div>
                                    <div class="datagrid-content">+65 {{ order.mobile }}</div>
                                </div>
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Order ID</div>
                                    <div class="datagrid-content">{{ order.id }}</div>
                                </div>
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Quantity</div>
                                    <div class="datagrid-content">{{ order.package_name }}</div>
                                </div>
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Payment Status</div>
                                    <div class="datagrid-content">
                                        <div v-if="order.payment_status">
                                            <span class="status status-green" v-if="order.payment_status == 'PAID'">PAID</span>
                                            <span class="status status-dark" v-else>{{ order.payment_status }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="datagrid-item" v-if="order.payment_method == 'POINTS'">
                                    <div class="datagrid-title">Points Redeem</div>
                                    <div v-if="order.payment_status == 'PAID'">
                                        <div class="datagrid-content">{{ order.points }}</div>
                                    </div>
                                </div>
                                <div class="datagrid-item" v-else>
                                    <div class="datagrid-title">Amount</div>
                                    <div v-if="order.payment_status == 'PAID'">
                                        <div class="datagrid-content">{{ order.package_amount_text }}</div>
                                    </div>
                                </div>

                                <div class="datagrid-item" v-if="order.remarks">
                                    <div class="datagrid-title">Remarks</div>
                                    <div class="datagrid-content">{{ order.remarks }}</div>
                                </div>
                                <div class="datagrid-item" v-if="order.payment_refno">
                                    <div class="datagrid-title">Payment Reference No.</div>
                                        <div class="datagrid-content">{{ order.payment_refno }}
                                    </div>
                                </div>

                                <div class="datagrid-item">
                                    <div class="datagrid-title">CEA Cert</div>
                                    <div class="datagrid-content">{{ order.cea_cert1 }} {{ order.cea_cert2 }}</div>
                                </div>
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Payment Method</div>
                                    <div v-if="order.payment_status == 'PAID' || order.payment_status == 'NA'">
                                        <div class="datagrid-content" v-if="order.payment_method == 'POINTS'">
                                            ERA Points
                                        </div>
                                        <div class="datagrid-content" v-else>
                                            {{ order.payment_method }}
                                        </div>
                                    </div>
                                </div>
                                <div class="datagrid-item">
                                    <div class="datagrid-title">Submitted Date</div>
                                    <div class="datagrid-content">
                                        {{ timeFormat(order.created_at) }}
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>
                    <div class="card mb-3" v-if="order.status == 'PENDING' || order.status == 'PENDING FOR APPROVAL' || order.status == 'PENDING FOR PAYMENT'">
                        <div class="card-body mb-4">
                            <h4 class="card-title">Select Template</h4>
                            <div class="card-subtitle">Click the image to select template</div>
                            <div class="row namecard-template text-center">
                                <div class="col-lg-3 mb-3" v-for="(template, index) in templates" :key="index">
                                    <img ondragstart="return false;" :src="template.front_template" @click="setTemplate(template)" :data-id="template.id" 
                                    :class="[ template.id == store.namecard.template.id ? 'active': '' ]"/>
                                    <h2 class="text-muted">{{ template.name }}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card mb-3">
                        <div class="card-header">
                            <div class="card-title">Namecard Preview</div>
                        </div>
                        <div class="card-body overflow-auto">
                            <CustomTa1 v-if="order.namecard_id == 1"/>
                            <CustomTa2 v-if="order.namecard_id == 2"/>
                            <CustomTa3 v-if="order.namecard_id == 3"/>
                            <CustomTa4 v-if="order.namecard_id == 4"/>
                            <CustomTs5 v-if="(order.namecard_id == 5)"/>
                        </div>
                        <div class="card-footer">

                            <div class="row mb-3">
                                <div class="col-md-4">&nbsp;</div>
                                <div class="col-md-8">
                                    <label class="form-check">
                                       <div class="middle">
                                            <div>
                                                <input class="form-check-input" type="checkbox" v-model="form.disclaimer" :value="true" style="border-color: #575e6a;">
                                            </div>
                                            <div>
                                                <span class="form-check-label">
                                                    <span class="text-red">*</span> Please review the above namecard artwork to ensure that all details and information provided are accurate and correct, as no changes or amendments will be allowed once approved!
                                                </span>
                                            </div>
                                       </div>
                                    </label>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col d-none d-sm-block">
                                    <router-link :to="{ name: 'Namecard' }" class="btn btn-link">
                                        <span>Go Back</span>
                                    </router-link>
                                </div>

                                <div class="col-auto" v-if="order.status == 'PENDING FOR PAYMENT'">
                                    <router-link :to="{ name: 'PaymentPage', params: { id: order.id } }" class="btn btn-success">
                                        <span>Proceed to Payment</span>
                                        <i class="ti ti-chevron-right"></i>
                                    </router-link>
                                </div>
                                
                                <div class="col-auto" v-if="order.status == 'PENDING FOR APPROVAL'">
                                    <button class="btn btn-success" @click="updateStatus('APPROVED')" v-if="form.disclaimer">
                                        <i class="ti ti-check"></i> <span>Approve Order</span>
                                    </button>
                                    <button class="btn btn-success" @click="updateStatus('APPROVED')" disabled v-else>
                                        <i class="ti ti-check"></i> <span>Approve Order</span>
                                    </button>
                                </div>

                                <div class="col-auto" v-if="order.status == 'PENDING' || order.status == 'PENDING FOR APPROVAL' || order.status == 'PENDING FOR PAYMENT'">
                                    <button class="btn btn-danger" @click="updateStatus('CANCELLED')">
                                        <i class="ti ti-x"></i> <span>Cancel Order</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-lg-4">
                    <OrderTracking :actions="actions"/>
                    <div class="card mb-3" v-if="order.status == 'PENDING' || order.status == 'PENDING FOR APPROVAL' || order.status == 'PENDING FOR PAYMENT'">
                        <div class="card-body">
                            <h3>If you need assistance about your order, please contact us.</h3>
                            <div class="mt-3">
                                Email : <a href="mailto:namecard@era.com.sg">namecard@era.com.sg</a> <br>
                                Phone :  62493142 / 62493191
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3" v-if="order.status == 'PENDING' || order.status == 'PENDING FOR APPROVAL' || order.status == 'PENDING FOR PAYMENT'">
                        <div class="card-body">
                            <div class="mb-3">
                                <div class="form-label">Initial Name</div>
                                <label class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" @change="nameToggle" :checked="nameToggleLabel">
                                    <span class="form-check-label">{{nameToggleLabel ? "Show" : "Hide"}}</span>
                                </label>
                            </div>

                            <div class="mb-3">
                                <div class="form-label">Name (Chinese)</div>
                                <input type="text" class="form-control" v-model="namecn">
                            </div>

                            <div class="mb-3">
                                <div class="form-label">Rank Name (Chinese)</div>
                                <label class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" @change="rankToggle" :checked="ranknamecnToggle">
                                    <span class="form-check-label">{{ranknamecnToggle ? "Show" : "Hide"}}</span>
                                </label>
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Email</label>
                                <input type="email" class="form-control" v-model="email" :class="{ 'is-invalid': error.email}">
                                <div class="invalid-feedback">{{ error.email }}</div>
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Cert-In-CES</label>
                                <input type="text" class="form-control mb-1" v-model="store.namecard.user.certInCes" maxlength="30">
                                <input type="text" class="form-control" v-model="store.namecard.user.certInCes2" maxlength="30">
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Social Media Accounts</label>

                                <div class="form-group row mb-3">
                                    <div class="col-md-3 col-form-label">Website</div>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" v-model="store.namecard.social.web" maxlength="30">
                                    </div>
                                </div>

                                <div class="form-group row mb-3">
                                    <div class="col-md-3 col-form-label">Facebook</div>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" v-model="store.namecard.social.fb" maxlength="30">
                                    </div>
                                </div>

                                <div class="form-group row mb-3">
                                    <div class="col-md-3 col-form-label">Instagram</div>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" v-model="store.namecard.social.ig" maxlength="30">
                                    </div>
                                </div>

                                <div class="form-group row mb-3">
                                    <div class="col-md-3 col-form-label">Tiktok</div>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" v-model="store.namecard.social.tiktok" maxlength="30">
                                    </div>
                                </div>
                            </div>

                            <div class="mt-4">
                                <div class="d-flex">
                                    <button type="submit" class="btn btn-success ms-auto" @click="updateOrder('PENDING')">
                                        <span>Update Order</span> 
                                        <i class="ti ti-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-cards">
                <div class="col-lg-12">
                    
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import PageTitle from '../../components/PageTitle.vue';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import axios from 'axios';
import { store } from '../../store.js';
import OrderTracking from './components/OrderTracking.vue';
import CustomTa1 from './components/CustomTa1.vue';
import CustomTa2 from './components/CustomTa2.vue';
import CustomTa3 from './components/CustomTa3.vue';
import CustomTa4 from './components/CustomTa4.vue';
import CustomTs5 from './components/CustomTs5.vue';
import { timeFormat } from '@/helpers/me';

export default {
    components: {
        PageTitle,
        OrderTracking,
        CustomTa1,
        CustomTa2,
        CustomTa3,
        CustomTa4,
        CustomTs5
    },
    setup(){
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const order = ref([]);
        const meta = ref([]);
        const actions = ref([]);
        const templates = ref([]);
        const ranknamecnToggle = ref();
        const name = ref('');
        const namenoInitial = ref('');
        const namewithInitial = ref('');
        const nameToggleLabel = ref();
        const namecn = ref('');
        const positioncn = ref('');
        const social = ref([]);
        const email = ref('');
        const error = ref([]);
        const form = ref([]);

        async function getOrder() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/namecard/orders/'+route.params.id)
            .then(function (res) {
                store.loading = false;
                actions.value = res.data.actions;
                order.value = res.data.order;
                store.namecard.template = res.data.namecard;
                store.namecard.user = {
                    name: res.data.order.user_name,
                    namecn: res.data.order.user_name_cn,
                    photo: res.data.order.photo,
                    phone: res.data.order.mobile,
                    email: res.data.order.email,
                    position: res.data.order.rank,
                    ranknamecn: res.data.order.rank_cn,
                    cea_regno: res.data.order.cea_regno,
                    res_cert: res.data.order.res_cert,
                    certInCes: res.data.order.cea_cert1,
                    certInCes2: res.data.order.cea_cert2,
                    division_group_name: res.data.order.division_group_name,
                    division_name: res.data.order.division_name
                };
                email.value = res.data.order.email;
                store.namecard.social = res.data.order.social;
                namenoInitial.value = res.data.order.user_name_noinitial;
                namewithInitial.value = res.data.order.user_name_withinitial;
                nameToggleLabel.value = res.data.order.hide_initial == 'N' ? true : false;
                name.value = res.data.order.user_name;
                namecn.value = res.data.order.user_name_cn;
                positioncn.value = res.data.order.position_cn;
                ranknamecnToggle.value = res.data.order.rank_cn ? true : false;

                if(res.data.order.hide_initial == 'N') {
                    store.namecard.user.name = namewithInitial.value;
                } else {
                    store.namecard.user.name = namenoInitial.value;
                }
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 404) {
                    router.push({ name: 'NamecardManage' });
                } else if (error.response && error.response.status == 403) {
                    router.push({ name: 'Home' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        async function getTemplates() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/namecard/templates',{
                params: {
                    user_type: 'ASSOC'
                }
            })
            .then(function (res) {
                store.loading = false;
                templates.value = res.data.namecards;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
        onMounted(() => {
            if (!localStorage.getItem('authToken')) {
                router.push({ name: 'Logout' });
                return false;
            }
            getOrder();
            getTemplates();
        });

        return { store, router, route, templates, swal, order, email, error, meta, actions, social, timeFormat, ranknamecnToggle, namenoInitial, namewithInitial, name, namecn, nameToggleLabel, positioncn, form };
    },
    methods: {
        setTemplate (template) {
            this.order.namecard_id = template.id;
            store.namecard.template = template;
        },
        async updateStatus (status) {
            let model = this;
            if(status == 'CANCELLED')
            {
                model.swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '',
                    confirmButtonText: 'Yes, cancel this order!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        model.updateStatusAPI(status);
                    }
                })
            } else if(status == 'APPROVED') 
            {
                model.updateStatusAPI(status);
            }
        },
        async updateStatusAPI(status) {
            store.loading = true;
            let model = this;
            await axios
            .put(process.env.VUE_APP_ENDPOINT+'/namecard/orders/'+this.route.params.id,{
                action: status
            })
            .then(function (res) {
                store.loading = false;
                model.swal({
                    icon: 'success',
                    text: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                });
                model.router.push({ name: 'Namecard' });
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        async updateOrder(status) {
            this.error.form = false;
            this.clear();

            if (!this.email && status == 'PENDING') {
                this.error.form = true;
                this.error.email = 'Email is required';
            }

            if (!this.error.form) {
                store.loading = true;
                let model = this;
                let cert = '';

                if (store.namecard.user.certInCes && store.namecard.user.certInCes2) {
                    cert = store.namecard.user.certInCes + "\n" + store.namecard.user.certInCes2;
                } else if (store.namecard.user.certInCes && !store.namecard.user.certInCes2) {
                    cert = store.namecard.user.certInCes;
                } else if (!store.namecard.user.certInCes && store.namecard.user.certInCes2) {
                    cert = store.namecard.user.certInCes2;
                }

                await axios
                .put(process.env.VUE_APP_ENDPOINT+'/namecard/order/update/'+this.route.params.id,{
                    action: status,
                    email: store.namecard.user.email,
                    name: store.namecard.user.name,
                    name_cn: store.namecard.user.namecn,
                    rank_cn: store.namecard.user.ranknamecn,
                    cea_cert: cert,
                    social: {
                        web: store.namecard.social.web,
                        fb: store.namecard.social.fb,
                        ig: store.namecard.social.ig,
                        tiktok: store.namecard.social.tiktok,
                    },
                    namecard_id: store.namecard.template.id,
                    hide_initial: this.nameToggleLabel ? 'N':'Y'
                })
                .then(function (res) {
                    store.loading = false;
                    model.swal({
                        icon: 'success',
                        text: res.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                    model.router.push({ name: 'Namecard' });
                })
                .catch(function (error) {
                    store.loading = false;
                    if (error.response && error.response.status == 401) {
                        model.router.push({ name: 'Logout' });
                    } else if (error.response && error.response.status == 422) {
                        model.swal({
                            icon: 'error',
                            text: error.response.data.message,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    } else {
                        model.swal({
                            icon: 'error',
                            text: model.app.appContext.config.globalProperties.$error500,
                            showCloseButton: true,
                            showConfirmButton: false
                        });
                    }
                });
            }
        },
        rankToggle() {
            this.ranknamecnToggle = !this.ranknamecnToggle;
            if(this.ranknamecnToggle)
            {
                store.namecard.user.ranknamecn = this.positioncn;
            } else {
                store.namecard.user.ranknamecn = '';
            }
        },
        nameToggle() {
            let model = this;
            model.nameToggleLabel = !model.nameToggleLabel;
            if(model.nameToggleLabel)
            {
                store.namecard.user.name = model.namewithInitial;
            } else {
                store.namecard.user.name = model.namenoInitial;
            }
        },
        clear() {
            this.error = [];
        }
    },
    watch: {
        namecn() {
            store.namecard.user.namecn = this.namecn;
        },
        email () {
            if (!this.email) {
                this.error.form = true;
                this.error.email = 'Email required';
            } else {
                if (store.namecard.user) {
                    store.namecard.user.email = this.email;
                }
                this.error.form = false;
                this.error.email = '';
            }
        },
    }
}
</script>
<style>
.middle { 
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal .modal-footer {
    padding-top: 10px;
    background-color: #f6f8fb;
    border-top: #e6e7e9 solid 1px;
}
</style>