<template>
    <PageTitle title="Manage Namecard Orders" :breadcrumb="[ { name: 'Manage Namecard Orders' } ]">
        <div class="btn-group">
            <button class="btn me-3" type="button" @click="goExport()">
                <i class="ti ti-download"></i> <span>Export</span>
            </button>
                                    
            <button type="button" class="btn btn-red dropdown-toggle no-arrow-down" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="ti ti-plus"></i> <span>New Order</span> 
            </button>
            <ul class="dropdown-menu">
                <li>
                    <router-link :to="{  name: 'AddAssocOrder' }" class="dropdown-item">
                        <span>Order for Associate</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{  name: 'AddStaffOrder' }" class="dropdown-item">
                        <span>Order for Staff</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </PageTitle>
    <div class="page-body">
        <div class="container-xl">
            <div class="row row-cards">
                <div class="col-12">
                    <div class="card mb-3">
                        <div class="card-body border-bottom py-3">
                            <div class="d-flex">
                                <div class="text-muted">
                                    <div class="dropdown">
                                        

                                        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="ti ti-filter"></i> <span>Status</span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item" href="#" :class="{ 'active': route.query.status == '' }"  @click="filterStatus('')">All</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 'PENDING' }" href="#" @click="filterStatus('PENDING')">Pending</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 'PAID' }" href="#" @click="filterStatus('PAID')">Paid</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 'APPROVED' }" href="#" @click="filterStatus('APPROVED')">Approved</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 'PROCESSING' }" href="#" @click="filterStatus('PROCESSING')">Processing</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 'ARRIVED' }" href="#" @click="filterStatus('ARRIVED')">Arrived HQ</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 'READY' }" href="#" @click="filterStatus('READY')">Ready for Collection</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 'COLLECTED' }" href="#" @click="filterStatus('COLLECTED')">Collected</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 'REJECTED' }" href="#" @click="filterStatus('REJECTED')">Rejected</a></li>
                                            <li><a class="dropdown-item" :class="{ 'active': route.query.status == 'CANCELLED' }" href="#" @click="filterStatus('CANCELLED')">Cancelled</a></li>
                                        </ul>

                                        <button class="btn ms-3 mb-2 mb-md-0 btn-blue" 
                                            v-if="route.query.status == 'PROCESSING'" @click="batchUpdate('ARRIVED')">
                                            <i class="ti ti-check"></i> <span>Arrived HQ</span> <span class="badge ms-2" v-if="form.checkCount">{{ form.checkCount }}</span>
                                        </button>

                                        <button class="btn ms-3 mb-2 mb-md-0 btn-blue" 
                                            v-if="route.query.status == 'ARRIVED'" @click="batchUpdate('READY')">
                                            <i class="ti ti-check"></i> <span>Ready for Collection</span> <span class="badge ms-2" v-if="form.checkCount">{{ form.checkCount }}</span>
                                        </button>

                                        <button class="btn ms-3 mb-2 mb-md-0 btn-blue" 
                                            v-if="route.query.status == 'READY'">
                                            <i class="ti ti-check"></i> <span>Collected</span> <span class="badge ms-2" v-if="form.checkCount">{{ form.checkCount }}</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="ms-auto text-muted">
                                    <div class="input-icon">
                                    <input type="text" v-model="q" class="form-control form-control-rounded" placeholder="Search…" @keyup.enter="makeSearch">
                                        <span class="input-icon-addon">
                                            <i class="ti ti-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th class="w-1">
                                            <input class="form-check-input" type="checkbox" v-model="checkAll" @click="checkAllToggle" v-if="(
                                                route.query.status == 'PROCESSING' || 
                                                route.query.status == 'ARRIVED' ||
                                                route.query.status == 'READY'
                                            )">
                                        </th>
                                        <th class="w-5">Order No</th>
                                        <th>User ID</th>
                                        <th>Name</th>
                                        <th>Quantity</th>
                                        <th class="w-5">Amount</th>
                                        <th>
                                            <div class="ms-auto lh-1">
                                                <div class="dropdown">
                                                <a class="dropdown-toggle text-muted" href="#" data-bs-toggle="dropdown">Status</a>
                                                <div class="dropdown-menu dropdown-menu-end text-capitalize" data-popper-placement="bottom-end">
                                                    <a class="dropdown-item" href="#" :class="{ 'active': route.query.status == '' }"  @click="filterStatus('')">All</a>
                                                    <a class="dropdown-item" :class="{ 'active': route.query.status == 'PENDING' }" href="#" @click="filterStatus('PENDING')">Pending</a>
                                                    <a class="dropdown-item" :class="{ 'active': route.query.status == 'PAID' }" href="#" @click="filterStatus('PAID')">Paid</a>
                                                    <a class="dropdown-item" :class="{ 'active': route.query.status == 'APPROVED' }" href="#" @click="filterStatus('APPROVED')">Approved</a>
                                                    <a class="dropdown-item" :class="{ 'active': route.query.status == 'PROCESSING' }" href="#" @click="filterStatus('PROCESSING')">Processing</a>
                                                    <a class="dropdown-item" :class="{ 'active': route.query.status == 'ARRIVED' }" href="#" @click="filterStatus('ARRIVED')">Arrived HQ</a>
                                                    <a class="dropdown-item" :class="{ 'active': route.query.status == 'READY' }" href="#" @click="filterStatus('READY')">Ready for Collection</a>
                                                    <a class="dropdown-item" :class="{ 'active': route.query.status == 'COLLECTED' }" href="#" @click="filterStatus('COLLECTED')">Collected</a>
                                                    <a class="dropdown-item" :class="{ 'active': route.query.status == 'REJECTED' }" href="#" @click="filterStatus('REJECTED')">Rejected</a>
                                                    <a class="dropdown-item" :class="{ 'active': route.query.status == 'CANCELLED' }" href="#" @click="filterStatus('CANCELLED')">Cancelled</a>
                                                </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th>Order At</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in orders.data" :key="index">
                                        <td>
                                            <input class="form-check-input check" type="checkbox" :data-id="row.id" @click="checkToggle" v-if="(
                                                route.query.status == 'PROCESSING' || 
                                                route.query.status == 'ARRIVED' ||
                                                route.query.status == 'READY'
                                                )">
                                        </td>
                                        <td class="text-center">
                                            <router-link :to="{ name: 'NamecardManageDetails', params: { id: row.id } }" 
                                            class="">
                                                {{ row.id }}
                                            </router-link>
                                        </td>
                                        <td>{{ row.order_by }}</td>
                                        <td>{{ row.user_name }}</td>
                                        <td>{{ row.package_name }}</td>
                                        <td class="text-end">{{ row.amount_text }}</td>
                                        <td><OrderStatus :orderStatus="row.status"></OrderStatus></td>
                                        <td>{{ timeFormat(row.created_at) }}</td>
                                        <td>
                                            <div class="dropdown">
                                                <a class="btn btn-sm btn-ghost-red dropdown-toggle no-arrow-down" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ti ti-dots"></i>
                                                </a>

                                                <ul class="dropdown-menu">
                                                    <li v-if="row.status == 'PROCESSING' || row.status == 'READY' || row.status == 'ARRIVED'">
                                                        <h6 class="dropdown-header">Actions</h6>
                                                    </li>
                                                    <li v-if="row.status == 'PROCESSING'">
                                                        <button class="dropdown-item" @click="updateStatus(row.id,'ARRIVED')">
                                                            Arrived HQ
                                                        </button>
                                                    </li>
                                                    <li v-if="row.status == 'ARRIVED'">
                                                        <button class="dropdown-item" @click="updateStatus(row.id,'READY')">
                                                            Ready for Collection
                                                        </button>
                                                    </li>
                                                    <li v-if="row.status == 'READY'">
                                                        <button class="dropdown-item" @click="updateStatus(row.id,'COLLECTED')">
                                                            Collected
                                                        </button>
                                                    </li>
                                                    <li><h6 class="dropdown-header">Connections</h6></li>
                                                    <li>
                                                        <router-link class="dropdown-item" :to="{ name: 'NamecardManageDetails', params: { id: row.id } }">
                                                            View Details <i class="ti ti-arrow-right"></i>
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer paginationBox">
                            <v-pagination
                                v-model="meta.current_page"
                                :pages="meta.last_page"
                                :range-size="1"
                                active-color="#DCEDFF"
                                @update:modelValue="goSearch"
                            />
                            <div class="item_counter">1 of {{ meta.last_page }} ({{ meta.total }} items)</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import PageTitle from '../../components/PageTitle.vue';
import OrderStatus from './components/OrderStatus.vue';
import { ref, onMounted, inject, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from "vue-router";
import VPagination from "@hennge/vue3-pagination";
import axios from 'axios';
import { store } from '../../store.js';
import { timeFormat } from '@/helpers/me';

export default {
    components: {
        PageTitle,
        OrderStatus,
        VPagination
    },
    setup(){
        const app = getCurrentInstance();
        const router = useRouter();
        const route = useRoute();
        const swal = inject('$swal');
        const orders = ref([]);
        const meta = ref([]);
        const q = ref([]);
        const search = ref([]);
        const form = ref([]);
        const checkAll = ref(false);

        async function getOrders() {
            store.loading = true;
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/namecard/manage',{ 
                params: route.query
            })
            .then(function (res) {
                store.loading = false;
                orders.value = res.data.orders;
                meta.value = res.data.orders.meta;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 403) {
                    router.push({ name: 'Home' });
                } else if (error.response && error.response.status == 422) {
                    swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    swal({
                        icon: 'error',
                        text: app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }

        onMounted(() => {
            if (!localStorage.getItem('authToken')) {
                router.push({ name: 'Logout' });
                return false;
            }
            getOrders();
        });

        return { router, route, swal, orders, store, meta, timeFormat, q, search, form, checkAll };
    },
    methods: {
        batchUpdate(status) {
            let ids = [];
            if (!this.form.checkCount) {
                return false;
            }
            let checkboxes = document.getElementsByClassName('check');
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    ids[i] = checkboxes[i].getAttribute('data-id');
                }
            }
            this.batchUpdateStatus(ids,status);
        },
        async batchUpdateStatus(ids,status) {
            store.loading = true;
            let model = this;
            await axios
            .post(process.env.VUE_APP_ENDPOINT+'/namecard/manage/batchUpdateStatus',{
                action: status,
                ids: ids
            })
            .then(function (res) {
                store.loading = false;
                model.swal({
                    icon: 'success',
                    text: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                });
                model.filterStatus('');
                model.uncheckAll();
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        checkToggle() {
            this.countAllCheck();
        },
        checkAllToggle() {
            this.form.checkCount = 0;
            let checkboxes = document.getElementsByClassName('check');
            if (this.checkAll == true) {
                this.checkAll = false;
                for (let i = 0; i < checkboxes.length; i++) {
                    checkboxes[i].checked = false;
                }
            } else {
                this.checkAll = true;
                for (let i = 0; i < checkboxes.length; i++) {
                    checkboxes[i].checked = true;
                }
            }
            this.countAllCheck();
        },
        countAllCheck() {
            this.form.checkCount = 0;
            let checkboxes = document.getElementsByClassName('check');
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked == true) {
                    this.form.checkCount++;
                }
            }
        },
        uncheckAll() {
            this.form.checkCount = 0;
            this.checkAll = false;
            let checkboxes = document.getElementsByClassName('check');
            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
        },

        filterStatus(status) {
            this.meta.current_page = 1;
            this.search.status = status;
            this.goSearch();
        },
        makeSearch() {
            this.meta.current_page = 1;
            this.search.status = '';
            this.goSearch();
        },
        async goExport() {
            store.loading = true;
            let model = this;
            let query = {
                q: this.q,
                page: this.meta.current_page,
                status: this.search.status
            }
            await axios({
                url: process.env.VUE_APP_ENDPOINT+'/namecard/export',
                method:'get',
                responseType:'blob',
                params: query
            })
            .then(function (res) {
                store.loading = false;
                const fileBlob = new Blob([res.data]);
                const fileUrlObject = URL.createObjectURL(fileBlob);
                const link = document.createElement('a');
                link.href = fileUrlObject;
                link.download = 'namecard-report.xlsx';
                link.click();
                URL.revokeObjectURL(fileUrlObject);
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        async goSearch() {
            store.loading = true;
            let model = this;
            let query = {
                q: this.q,
                page: this.meta.current_page,
                status: this.search.status
            }
            this.router.push({ name: 'NamecardManage', query: query });
            await axios
            .get(process.env.VUE_APP_ENDPOINT+'/namecard/manage',{ 
                params: query
            })
            .then(function (res) {
                store.loading = false;
                model.orders = res.data.orders;
                model.meta = res.data.orders.meta;
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        },
        async updateStatus(id,status) {
            store.loading = true;
            let model = this;
            await axios
            .put(process.env.VUE_APP_ENDPOINT+'/namecard/manage/'+id,{
                action: status
            })
            .then(function (res) {
                store.loading = false;
                model.swal({
                    icon: 'success',
                    text: res.data.message,
                    showCloseButton: true,
                    showConfirmButton: false
                });
                model.filterStatus('');
            })
            .catch(function (error) {
                store.loading = false;
                if (error.response && error.response.status == 401) {
                    model.router.push({ name: 'Logout' });
                } else if (error.response && error.response.status == 422) {
                    model.swal({
                        icon: 'error',
                        text: error.response.data.message,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                } else {
                    model.swal({
                        icon: 'error',
                        text: model.app.appContext.config.globalProperties.$error500,
                        showCloseButton: true,
                        showConfirmButton: false
                    });
                }
            });
        }
    }
}
</script>